import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import GettingStarted from '../pages/GettingStarted';

const GettingStartedWrapper: React.FC = () => {
  const { benefitsSummary } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    
    if (benefitsSummary?.stage === 'full') {
      navigate('/benefits-summary', { replace: true });
    } else if (benefitsSummary?.stage === 'estimate') {
      navigate('/quick-results', { replace: true });
    } else {
      
    }
  }, [benefitsSummary, navigate, location]);

  return <GettingStarted />;
};

export default GettingStartedWrapper;
