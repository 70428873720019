import React, { useState, useEffect } from 'react';
import { Resources, EmploymentTypeValue, EmploymentLengthValue, PayFrequencyValue } from '../../utils/resources';
import { useForm } from '../../contexts/FormContext';
import { MdWarning } from 'react-icons/md';
import { format, addDays, parse, isAfter, startOfDay, isSameDay } from 'date-fns';
import DateInput from '../../components/DateInput';

const Step6: React.FC = () => {
  const { formData, updateField, validateField, isFieldTouched, touchedFields } = useForm();

  // Clear stored dates if they're in the past
  useEffect(() => {
    const today = startOfDay(new Date());
    let shouldUpdateDate1 = false;
    let shouldUpdateDate2 = false;

    if (formData.incomeNextDate1) {
      try {
        const date1 = parse(formData.incomeNextDate1, 'dd-MM-yyyy', new Date());
        // Allow today's date for the first pay date
        if (!isAfter(date1, today) && !isSameDay(date1, today)) {
          shouldUpdateDate1 = true;
        }
      } catch (error) {
        shouldUpdateDate1 = true;
      }
    }

    if (formData.incomeNextDate2) {
      try {
        const date2 = parse(formData.incomeNextDate2, 'dd-MM-yyyy', new Date());
        if (!isAfter(date2, today)) {
          shouldUpdateDate2 = true;
        }
      } catch (error) {
        shouldUpdateDate2 = true;
      }
    }

    if (shouldUpdateDate1) {
      updateField('incomeNextDate1', '');
    }
    if (shouldUpdateDate2) {
      updateField('incomeNextDate2', '');
    }
  }, [formData.incomeNextDate1, formData.incomeNextDate2, updateField]);

  const [nextPayDate1, setNextPayDate1] = useState<Date | null>(() => {
    if (!formData.incomeNextDate1) return null;
    try {
      const today = startOfDay(new Date());
      const parsedDate = parse(formData.incomeNextDate1, 'dd-MM-yyyy', new Date());
      // Allow today's date for the first pay date
      return (isAfter(parsedDate, today) || isSameDay(parsedDate, today)) ? parsedDate : null;
    } catch (error) {
      return null;
    }
  });

  const [nextPayDate2, setNextPayDate2] = useState<Date | null>(() => {
    if (!formData.incomeNextDate2 || !nextPayDate1) return null;
    try {
      const today = startOfDay(new Date());
      const parsedDate = parse(formData.incomeNextDate2, 'dd-MM-yyyy', new Date());
      return isAfter(parsedDate, today) && isAfter(parsedDate, nextPayDate1) ? parsedDate : null;
    } catch (error) {
      return null;
    }
  });

  const handleEmploymentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as EmploymentTypeValue;
    updateField('employment_type', value);
  };

  const handleEmploymentLengthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as EmploymentLengthValue;
    updateField('employment_length', value);
  };

  const handlePayFrequencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as PayFrequencyValue;
    updateField('pay_frequency', value);
  };

  const handleNextPayDate1Change = (date: Date | null) => {
    setNextPayDate1(date);
    if (date) {
      updateField('incomeNextDate1', format(date, 'dd-MM-yyyy'));
      // Clear second date if it's not after the new first date
      if (nextPayDate2 && !isAfter(nextPayDate2, date)) {
        setNextPayDate2(null);
        updateField('incomeNextDate2', '');
      }
    } else {
      updateField('incomeNextDate1', '');
      // Clear second date if first date is cleared
      setNextPayDate2(null);
      updateField('incomeNextDate2', '');
    }
  };

  const handleNextPayDate2Change = (date: Date | null) => {
    setNextPayDate2(date);
    if (date) {
      updateField('incomeNextDate2', format(date, 'dd-MM-yyyy'));
    } else {
      updateField('incomeNextDate2', '');
    }
  };

  // Get validation errors for each field
  const employmentTypeError = (isFieldTouched('employment_type') || touchedFields['employment_type'])
    ? validateField('employment_type', formData.employment_type || '')
    : undefined;

  const employerNameError = (isFieldTouched('employer_name') || touchedFields['employer_name'])
    ? validateField('employer_name', formData.employer_name || '')
    : undefined;

  const employmentLengthError = (isFieldTouched('employment_length') || touchedFields['employment_length'])
    ? validateField('employment_length', formData.employment_length || '')
    : undefined;

  const incomeAmountError = (isFieldTouched('income_amount') || touchedFields['income_amount'])
    ? validateField('income_amount', formData.income_amount || '')
    : undefined;

  const payFrequencyError = (isFieldTouched('pay_frequency') || touchedFields['pay_frequency'])
    ? validateField('pay_frequency', formData.pay_frequency || '')
    : undefined;

  // Handle date validation errors with proper type checking
  let nextPayDate1Error: string | undefined = undefined;
  if (isFieldTouched('incomeNextDate1') || touchedFields['incomeNextDate1']) {
    const validationResult = validateField('incomeNextDate1', formData.incomeNextDate1 || '');
    nextPayDate1Error = validationResult || undefined;
  }

  let nextPayDate2Error: string | undefined = undefined;
  if (isFieldTouched('incomeNextDate2') || touchedFields['incomeNextDate2']) {
    const validationResult = validateField('incomeNextDate2', formData.incomeNextDate2 || '');
    nextPayDate2Error = validationResult || undefined;
  }

  const isUnemployed = formData.employment_type === 'unemployed';

  return (
    <div className="flex flex-col space-y-6">
      <div>
        <h1 className="text-2xl font-bold">
          What do you do?
        </h1>
      </div>

      {/* Alert Box */}
      <div className="bg-secondaryBtn-hover rounded-lg p-4 flex items-center space-x-3">
        <MdWarning className="h-6 w-6 text-primary" />
        <p className="text-gray-800">
          We use this information purely for internal purposes, we will never contact your employer.
        </p>
      </div>

      {/* Employment Type */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">
          What is your main source of income?
        </label>
        <select
          value={formData.employment_type || ''}
          name="employment_type"
          onChange={handleEmploymentTypeChange}
          className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border ${
            employmentTypeError ? 'border-red-500' : 'border-gray-300'
          } focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md`}
          aria-invalid={!!employmentTypeError}
          aria-describedby={employmentTypeError ? "employment-type-error" : undefined}
        >
          <option value="">Select an option</option>
          {Object.entries(Resources.employmentTypeOptions).map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        {employmentTypeError && (
          <p className="mt-2 text-sm text-red-600" id="employment-type-error" role="alert">
            {employmentTypeError}
          </p>
        )}
      </div>

      {!isUnemployed && (
        <>
          {/* Employer Name */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Employer Name
            </label>
            <input
              type="text"
              name="employer_name"
              value={formData.employer_name || ''}
              onChange={(e) => updateField('employer_name', e.target.value)}
              className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border ${
                employerNameError ? 'border-red-500' : 'border-gray-300'
              } focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md`}
              aria-invalid={!!employerNameError}
              aria-describedby={employerNameError ? "employer-name-error" : undefined}
            />
            {employerNameError && (
              <p className="mt-2 text-sm text-red-600" id="employer-name-error" role="alert">
                {employerNameError}
              </p>
            )}
          </div>

          {/* Employment Length */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              How long have you been there?
            </label>
            <select
              value={formData.employment_length || ''}
              name="employment_length"
              onChange={handleEmploymentLengthChange}
              className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border ${
                employmentLengthError ? 'border-red-500' : 'border-gray-300'
              } focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md`}
              aria-invalid={!!employmentLengthError}
              aria-describedby={employmentLengthError ? "employment-length-error" : undefined}
            >
              <option value="">Select an option</option>
              {Object.entries(Resources.employmentLengthOptions).map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            {employmentLengthError && (
              <p className="mt-2 text-sm text-red-600" id="employment-length-error" role="alert">
                {employmentLengthError}
              </p>
            )}
          </div>

          {/* Income Amount */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              How much do you get paid? (monthly after tax)
            </label>
            <div className="relative mt-1">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                £
              </span>
              <input
                type="number"
                name="income_amount"
                value={formData.income_amount || ''}
                onChange={(e) => updateField('income_amount', e.target.value)}
                className={`block w-full pl-7 pr-12 py-2 border ${
                  incomeAmountError ? 'border-red-500' : 'border-gray-300'
                } rounded-md focus:ring-blue-500 focus:border-blue-500`}
                aria-invalid={!!incomeAmountError}
                aria-describedby={incomeAmountError ? "income-amount-error" : undefined}
              />
            </div>
            {incomeAmountError && (
              <p className="mt-2 text-sm text-red-600" id="income-amount-error" role="alert">
                {incomeAmountError}
              </p>
            )}
          </div>

          {/* Pay Frequency */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              When are you paid?
            </label>
            <select
              value={formData.pay_frequency || ''}
              onChange={handlePayFrequencyChange}
              name="pay_frequency"
              className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border ${
                payFrequencyError ? 'border-red-500' : 'border-gray-300'
              } focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md`}
              aria-invalid={!!payFrequencyError}
              aria-describedby={payFrequencyError ? "pay-frequency-error" : undefined}
            >
              <option value="">Select an option</option>
              {Object.entries(Resources.payFrequencyOptions).map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            {payFrequencyError && (
              <p className="mt-2 text-sm text-red-600" id="pay-frequency-error" role="alert">
                {payFrequencyError}
              </p>
            )}
          </div>

          {/* Next Pay Dates */}
          <div className="space-y-2">
            <DateInput
              dataSensitive="false"
              selected={nextPayDate1}
              name="incomeNextDate1"
              onChange={handleNextPayDate1Change}
              minDate={new Date()}
              maxDate={addDays(new Date(), 31)}
              label="You are going to be paid next on"
              error={nextPayDate1Error}
              placeholderText="DD/MM/YYYY"
              dateFormat="dd-MM-yyyy"
            />
          </div>

          <div className="space-y-2">
            <DateInput
              dataSensitive="false"
              selected={nextPayDate2}
              name="incomeNextDate2"
              onChange={handleNextPayDate2Change}
              minDate={nextPayDate1 || new Date()}
              maxDate={addDays(new Date(), 62)}
              label="And when will you be next paid after that?"
              error={nextPayDate2Error}
              placeholderText="DD/MM/YYYY"
              disabled={!nextPayDate1}
              dateFormat="dd-MM-yyyy"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Step6;
