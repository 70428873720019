export const Resources = {
  appName: 'Afforda',
  riskThreshold: 0.65,
  
  useOfFunds: {
    'Gambling': 'other',
    'Moving costs': 'moving_costs',
    'Repairs': 'emergency_cash',
    'One-off purchase': 'one_off_purchase',
    'Irregular bill payments': 'irregular_bills',
    'Unexpected expenses': 'unexpected_expenses',
    'Pay off another loan': 'debt_consolidation',
    'Travel expenses': 'travel_expense'
  } as const,

  titleItems: {
    'Mr': 'mr',
    'Miss': 'miss',
    'Mrs': 'mrs',
    'Ms': 'ms',
  } as const,

  howLongOptions: {
    '1-3 months': '3',
    '4-6 months': '6',
    '7-12 months': '12',
    '1-2 years': '24',
    '2-4 years': '48',
    'Over 4 years': '60'
  } as const,

  housingTypeOptions: {
    'A homeowner': 'owner',
    'Renting privately': 'tenant', 
    'Renting from the council': 'council_tenant',
    'Renting from a housing association': 'housing_association',
    'Living with relatives': 'living_with_parents',
    'Living rent-free': 'rent_free',
    'Staying at your employer': 'staying_at_employer',
    'Supported or exempt': 'supported_or_exempt',
    'Boarder or lodger': 'boarder_or_lodger',
    'Homeless': 'homeless',
    'Shared ownership': 'shared_ownership'
  } as const,

  maritalStatusOptions: {
    'Single': 'single',
    'Married': 'married',
    'Living together': 'living_with_partner',
    'Divorced': 'divorced',
    'Separated': 'separated',
    'Widowed': 'widowed',
    'Other': 'other'
  } as const,

  numberOfDependantsOptions: {
    '0': '0',
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9'
  } as const,

  employmentTypeOptions: {
    'Employed full-time': 'full_time',
    'Employed part-time': 'part_time',
    'Temping': 'temporary_employment',
    'Self-employed': 'self_employed',
    'Receiving benefits': 'disability_benefit',
    'Retired': 'retired',
    'Unemployed': 'unemployed'
  } as const,

  employmentLengthOptions: {
    '1-3 months': '3',
    '4-6 months': '6',
    '7-12 months': '12',
    '1-2 years': '24',
    '2-4 years': '48',
    'Over 4 years': '60'
  } as const,

  payFrequencyOptions: {
    'Last working day of the month': 'last_working_day_of_month',
    'Last monday of the month': 'last_monday_of_month',
    'Last tuesday of the month': 'last_tuesday_of_month',
    'Last wednesday of the month': 'last_wednesday_of_month',
    'Last thursday of the month': 'last_thursday_of_month',
    'Last friday of the month': 'last_friday_of_month',
    'Specific day of the month': 'specific_day_of_month',
    'Four weekly': 'four_weekly',
    'Weekly': 'weekly',
    'Fortnightly': 'fortnightly',
    'Semi monthly': 'semi_monthly'
  } as const,

  numberOfChildrenOptions: {
    '0': '0',
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10'
  } as const,

  immediateConfig: {
    title: 'Here are some available options for you',
    subtitle: 'Based on what you’ve told us',
    userNeeds: [
      'You need to make a one-off purchase',
      'You need to take care of this soon',
    ],
    helpText: 'Here’s how we think Afforda can best help',
    improvementTitle: 'Improve your finances',
    improvementText:
      'We’d like to help you get into a better position, for the next time something unexpected happens.',
  },

  sectionConfigurations: {
    retailDiscounts: {
      sectionHeader: 'Exclusive Discounts for you',
      sectionText:
        'Discounts available to help with your purchases.',
      ctaText: 'Reveal your discounts',
    },
    loan: {
      sectionHeader: 'Explore Loans and Credit Options',
      sectionText:
        'If you need credit, Afforda can help you find a lender.',
      ctaText: 'Check if credit options are available',
      subText: 'Representative 1333% APR'
    },
    grants: {
      sectionHeader: 'Check your eligibility for Government and Local Grants',
      sectionText: 'It’s possible that you could be eligible for Government or local grants.',
      ctaText: 'Check for available grants',
    },
    savingsAccount: {
      sectionHeader: 'Setup savings account',
      sectionText:
        'Preparing for unexpected expenses is key. Click below for more information on savings accounts, and how to start saving.',
      ctaText: 'Open your savings account today',
    },
    checkBenefits: {
      sectionHeader: 'Check your eligibility for Benefits',
      sectionText:
        'Over £17 billion of Government benefits are currently unclaimed in the UK. It’s possible you could be eligible for some.',
      ctaText: 'Check Government benefits',
    },
    saveOnShopping: {
      sectionHeader: 'Save on your Daily Expenses',
      sectionText:
        'Our discounts can help you cut down your daily expenses.',
      ctaText: 'Find everyday savings',
    }
  } as const,

  signpostingData: {
    gambling: {
      title: 'Support for Gambling Concerns',
      messages: [
        'If you or someone you care about is affected by gambling, know that support is available.',
        'Talking to someone can be a helpful first step.',
      ],
      helpLink: 'https://www.begambleaware.org/',
      organizationName: 'BeGambleAware',
      phoneNumber: '0808 8020 133',
    },
    over_indebtedness: {
      title: 'Managing Financial Challenges',
      messages: [
        'If you’re experiencing financial difficulties or struggling to manage debt, MoneyHelper is ready to provide guidance and support.',
        'They also offer tools and calculators that might help you.',
      ],
      helpLink:
        'https://www.moneyhelper.org.uk/en/money-troubles/dealing-with-debt/use-our-debt-advice-locator',
      organizationName: 'MoneyHelper',
      ctaText: 'Get Free Debt Advice',
      phoneNumber: '0800 138 1111',
    },
    insolvency: {
      title: 'Navigating Insolvency',
      messages: [
        'If you’re facing insolvency or considering options like an IVA, trust deed, bankruptcy, or debt relief order, know that there are resources available to help you understand the process and make informed decisions.',
      ],
      helpLink:
        'https://www.gov.uk/government/organisations/insolvency-service',
      organizationName: 'Insolvency Service',
      phoneNumber: '0300 678 0015',
    },
    mental_health_sad: {
      title: 'Prioritizing Your Well-being',
      messages: [
        'Your mental well-being is essential.',
        'If you’re going through a challenging time, remember that support is available.'
      ],
      helpLink: 'https://www.mind.org.uk/',
      organizationName: 'Mind',
      phoneNumber: '0300 123 3393',
    },
    mental_health_risk: {
      title: 'Immediate Support',
      messages: [
        'If you’re experiencing a crisis or having thoughts of self-harm, your well-being is the top priority.',
        'Please know that you’re not alone, and immediate help is available.',
      ],
      helpLink: 'https://www.samaritans.org/',
      organizationName: 'Samaritans',
      phoneNumber: '116 123',
    },
    addiction: {
      title: 'Support for Your Path to Recovery',
      messages: [
        'If you’re concerned about addiction or substance use, there are compassionate professionals ready to provide support and explore treatment options that can help you reclaim your well-being.',
      ],
      helpLink: 'https://www.talktofrank.com/',
      organizationName: 'FRANK',
      phoneNumber: '0300 123 6600',
    },
  } as const,

  allCategories: [
    { id: 1, name: 'Groceries & Supermarkets' },
    { id: 2, name: 'Food and Drink' },
    { id: 3, name: 'Home & Furniture' },
    { id: 4, name: 'Electronics & Technology' },
    { id: 5, name: 'Retail and Department Stores' },
    { id: 6, name: 'Fashion and Accessories' },
    { id: 7, name: 'Travel & Experiences' },
    { id: 8, name: 'Health & Fitness' },
    { id: 9, name: 'Beauty & Personal Care' },
    { id: 10, name: 'Gifts & Specialty Items' },
    { id: 11, name: 'Automotive & Transportation' },
    { id: 12, name: 'Entertainment & Media' },
    { id: 13, name: 'Black Friday Deals'},
    { id: 14, name: 'Black Friday Tech Offers'},
  ] as const,

  govtGrantsUrl: 'https://www.find-government-grants.service.gov.uk?utm_source=afforda'
} as const;

// Export types for form values
export type UseOfFundsValue = typeof Resources.useOfFunds[keyof typeof Resources.useOfFunds];
export type TitleValue = typeof Resources.titleItems[keyof typeof Resources.titleItems];
export type HowLongValue = typeof Resources.howLongOptions[keyof typeof Resources.howLongOptions];
export type HousingTypeValue = typeof Resources.housingTypeOptions[keyof typeof Resources.housingTypeOptions];
export type MaritalStatusValue = typeof Resources.maritalStatusOptions[keyof typeof Resources.maritalStatusOptions];
export type EmploymentTypeValue = typeof Resources.employmentTypeOptions[keyof typeof Resources.employmentTypeOptions];
export type EmploymentLengthValue = typeof Resources.employmentLengthOptions[keyof typeof Resources.employmentLengthOptions];
export type PayFrequencyValue = typeof Resources.payFrequencyOptions[keyof typeof Resources.payFrequencyOptions];
