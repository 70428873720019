import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../utils/api';
import { UIUtils } from '../utils/uiUtils';
import { useBottomNavBar } from '../contexts/BottomNavBarContext';
import DateInput from '../components/DateInput';
import { subYears, format } from 'date-fns';
import { Button } from '../components/Button';
import config from '../configs/config';
import { usePostLoginProcessor } from '../utils/PostLoginProcessor';

interface LocationState {
  email?: string;
  first_name?: string;
  last_name?: string;
  social_signup?: boolean;
  needs_email?: boolean;
  apple_sub?: string;
}

const SignUp: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  
  const [firstName, setFirstName] = useState(state?.first_name || '');
  const [lastName, setLastName] = useState(state?.last_name || '');
  const [email, setEmail] = useState(state?.email || '');
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [agreedToPrivacyPolicy, setAgreedToPrivacyPolicy] = useState(false);
  const [receiveUpdates, setReceiveUpdates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsVisible } = useBottomNavBar();
  const { processPostLogin } = usePostLoginProcessor();

  useEffect(() => {
    setIsVisible(false);
    return () => setIsVisible(true);
  }, [setIsVisible]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!agreedToPrivacyPolicy) {
      UIUtils.showMessageOnSnackBar('Please agree to the privacy policy');
      return;
    }
    if (!dateOfBirth) {
      UIUtils.showMessageOnSnackBar('Please enter your date of birth');
      return;
    }
    UIUtils.trackFormSubmit("0","success");
    setIsLoading(true);
    try {
      const signUpData: any = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        dob: format(dateOfBirth, 'yyyy-MM-dd'),
        privacy_consent: agreedToPrivacyPolicy,
        receive_updates: receiveUpdates,
        created_by: 'web-app',
      };

      // Only add social token data if this is a social signup AND we have the email
      if (state?.social_signup && !state?.needs_email) {
        const socialData = sessionStorage.getItem('social_signup_data');
        if (socialData) {
          const { token, provider, apple_sub } = JSON.parse(socialData);
          signUpData.social_token = {
            token,
            provider,
            device_info: {
              device: 'web',
              browser: navigator.userAgent,
              operating_system: navigator.platform
            }
          };
          if (apple_sub) {
            signUpData.apple_sub = apple_sub;
          }
        }
      }

      const response = await api.signUpUser(signUpData);
      
      // Check for error response in data
      if (Array.isArray(response.data) && response.data[0]?.status === 'ERROR') {
        const errorMessage = response.data[0].message;
        if (errorMessage.includes('Existing customer')) {
          // Clear social signup data if it exists
          if (state?.social_signup) {
            sessionStorage.removeItem('social_signup_data');
          }
          // Just navigate to login with email pre-filled
          navigate('/login', { state: { email } });
          return;
        } else {
          throw new Error(errorMessage);
        }
      }

      if (response.data.status === 'SUCCESS' && state?.social_signup && !state?.needs_email) {
        if (response.data.access_token) {
          // Clear social signup data
          sessionStorage.removeItem('social_signup_data');
          
          // Set the token
          api.setToken(response.data.access_token);
          
          // Fetch user data
          const userData = await api.fetchUserData();
          if (!userData.data) {
            throw new Error('No user data received from server');
          }

          // Process post login with the fetched user data
          const { redirectTo } = processPostLogin(
            userData.data,
            response.data.access_token,
            state?.apple_sub ? 'apple' : 'google'
          );

          UIUtils.showMessageOnSnackBar('Successfully signed up');
          navigate(redirectTo, { replace: true });
        } else {
          throw new Error('No access token received from server');
        }
      } else if (response.data.reference_id) {
        // For regular signup and Apple Sign In without email, proceed to email verification
        navigate(`/email-verification/${response.data.reference_id}`);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        const responseBody = error.response.data;
        // Check for both message formats: string and array
        const errorMessage = Array.isArray(responseBody) 
          ? responseBody[0]?.message 
          : responseBody.message;

        if (errorMessage?.includes('Existing customer')) {
          // Clear social signup data if it exists
          if (state?.social_signup) {
            sessionStorage.removeItem('social_signup_data');
          }
          // Just navigate to login with email pre-filled
          navigate('/login', { state: { email } });
        } else {
          UIUtils.showMessageOnSnackBar(errorMessage || 'Oops! Sign-up failed. Please try again later.');
        }
      } else {
        UIUtils.showMessageOnSnackBar('Oops! Sign-up failed. Please try again later.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (date: Date | null) => {
    setDateOfBirth(date);
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
     <div className="flex items-center justify-between pb-4 border-b">
        <div className="flex items-center">
          <button
            onClick={() => navigate(-1)}
            className="mr-4 text-gray-600 hover:text-gray-900"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <h1 className="text-xl">
            {state?.social_signup && !state?.needs_email
              ? "Complete Your Profile" 
              : "Create an Account"}
          </h1>
        </div>
      </div>
      <main className="flex-grow container mx-auto pt-4">
        {isLoading ? (
          <div className="text-center">
            Hang tight {firstName}! We're setting up your account...
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-6 max-w-md mx-auto">
            {state?.needs_email && (
              <div className="mb-6 p-4 bg-blue-50 text-blue-800 rounded-lg">
                Apple Sign In couldn't be completed as you chose not to share your email. Please complete the signup form below with your email address. You'll receive a verification code to confirm your email.
              </div>
            )}
            <div>
              <label htmlFor="firstName" className="form-label">First name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className="form-input"
              />
            </div>
            <div>
              <label htmlFor="lastName" className="form-label">Last name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                className="form-input"
              />
            </div>
            <div>
              <label htmlFor="email" className="form-label">Email</label>
              <input
                data-sensitive='true'
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="form-input"
                readOnly={state?.social_signup && !state?.needs_email}
              />
            </div>
            <div>
              <DateInput
                dataSensitive="true"
                selected={dateOfBirth}
                onChange={handleDateChange}
                name="dob"
                maxDate={subYears(new Date(), 18)}
                label="Date of birth"
                placeholderText="DD/MM/YYYY"
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                className="form-input"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  type="checkbox"
                  name="marketing_optin"
                  id="receiveUpdates"
                  checked={receiveUpdates}
                  onChange={(e) => setReceiveUpdates(e.target.checked)}
                  className="form-checkbox"
                />
              </div>
              <label htmlFor="receiveUpdates" className="ml-2 block text-sm text-secondary">
                We would like to keep you updated on loan offers, products and services we offer through Gain Credit LLC (trading as Afforda) by email. You can unsubscribe at any time by going to your profile settings.
              </label>
            </div>
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  type="checkbox"
                  name="privacy_consent"
                  id="agreedToPrivacyPolicy"
                  checked={agreedToPrivacyPolicy}
                  onChange={(e) => setAgreedToPrivacyPolicy(e.target.checked)}
                  required
                  className="form-checkbox"
                />
              </div>
              <label htmlFor="agreedToPrivacyPolicy" className="ml-2 text-sm text-secondary">
                I agree to the <a href={`${config.WORDPRESS_URL}/terms-of-use`} target="_blank" rel="noopener noreferrer" className="font-semibold underline text-primary hover:opacity-90">Terms of Use</a> and consent to the <a href={`${config.WORDPRESS_URL}/privacy-policy`} target="_blank" rel="noopener noreferrer" className="font-semibold underline text-primary hover:opacity-90">Privacy Policy</a>, which explains how my personal information will be processed and shared. This includes any sensitive information I may choose to provide, such as information about my health, and I'm aware that I can withdraw this consent at any time.
              </label>
            </div>
            <div>
              <Button
                text={state?.social_signup && !state?.needs_email ? "Complete Sign Up" : "Sign Up"}
                onClick={() => {}}
                type="submit"
                className="w-full"
              />
            </div>
          </form>
        )}
      </main>
    </div>
  );
};

export default SignUp;
