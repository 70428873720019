import React, { createContext, useContext, useState, ReactNode } from 'react';

interface BottomNavBarContextType {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const BottomNavBarContext = createContext<BottomNavBarContextType | undefined>(undefined);

export const BottomNavBarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <BottomNavBarContext.Provider value={{ isVisible, setIsVisible }}>
      {children}
    </BottomNavBarContext.Provider>
  );
};

export const useBottomNavBar = () => {
  const context = useContext(BottomNavBarContext);
  if (context === undefined) {
    throw new Error('useBottomNavBar must be used within a BottomNavBarProvider');
  }
  return context;
};
