import React from 'react';
import { UIUtils } from '../utils/uiUtils';

interface TextButtonProps {
  /** The text content of the button */
  text: string;
  /** Function to be called when button is clicked */
  onClick: () => void;
  /** Additional CSS classes */
  className?: string;
  /** Whether the button is disabled */
  disabled?: boolean;
  /** Title attribute for tooltip and accessibility */
  title?: string;
}

export const TextButton: React.FC<TextButtonProps> = ({
  text,
  onClick,
  className = '',
  disabled = false,
  title,
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    
    // Track button click
    UIUtils.trackButtonClick(text);
    onClick();
  };

  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      title={title}
      className={`
        text-primary
        font-medium
        underline
        underline-offset-2
        decoration-primary
        bg-transparent
        hover:opacity-80
        transition-opacity
        duration-200
        disabled:opacity-50
        disabled:cursor-not-allowed
        ${disabled ? 'pointer-events-none' : ''}
        ${className}
      `}
    >
      {text}
    </button>
  );
};
