import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Resources } from '../utils/resources';
import { Button } from './Button';
import { TextButton } from './TextButton';

interface SignpostingProps {
  category: string;
}

interface SignpostingData {
  title: string;
  messages: string[];
  helpLink: string;
  organizationName: string;
  phoneNumber: string;
  ctaText?: string;
}

const Signposting: React.FC<SignpostingProps> = ({ category }) => {
  const navigate = useNavigate();
  const signpostingData = (Resources as any).signpostingData?.[category] as SignpostingData | undefined;

  if (!signpostingData) {
    return (
      <div className="text-center">
        <p>Signposting data not found.</p>
      </div>
    );
  }

  const organizationName = signpostingData.organizationName;

  return (
    <div>
      <div className="flex items-center">
        <h2 className="text-xl font-semibold">
          {signpostingData.title}{' '}
          <TextButton text='(why am I seeing this?)' onClick={() => navigate('/review-actions')}/>
        </h2>
      </div>

      <div className="mt-5 space-y-2.5">
        {signpostingData.messages.map((message: string, index: number) => (
          <p key={index} className="text-base">
            {message}
          </p>
        ))}
      </div>

      <div className="mt-5 flex justify-center">
        <Button
          text={`Visit ${organizationName}`}
          onClick={() => window.open(signpostingData.helpLink, '_blank')}
        />
      </div>
    </div>
  );
};

export default Signposting;
