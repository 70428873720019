import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PinInput from 'react-pin-input';
import api from '../utils/api';
import { UIUtils } from '../utils/uiUtils';
import { useBottomNavBar } from '../contexts/BottomNavBarContext';
import { usePostLoginProcessor } from '../utils/PostLoginProcessor';
import Loader from '../components/Loader';
import { Button } from '../components/Button';
import { TextButton } from '../components/TextButton';

const EmailVerification: React.FC = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [remainingAttempts, setRemainingAttempts] = useState(0);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [resendCountdown, setResendCountdown] = useState(60);
  const [showAttemptsLeft, setShowAttemptsLeft] = useState(false);
  const [maskedEmail, setMaskedEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loaderMessage, setLoaderMessage] = useState('Loading ...');
  const { setIsVisible } = useBottomNavBar();
  const { processPostLogin } = usePostLoginProcessor();
  const location = useLocation();

  useEffect(() => {
    setIsVisible(false);
    return () => setIsVisible(true);
  }, [setIsVisible]);

  const navigate = useNavigate();
  const { totpUID } = useParams<{ totpUID: string }>();

  const fetchVerificationStatus = useCallback(async () => {
    if (!totpUID) {
      UIUtils.trackException('Missing totpUID');
      UIUtils.showMessageOnSnackBar('Invalid verification link');
      navigate('/');
      return;
    }

    try {
      const response = await api.fetchVerificationStatus(totpUID);
      setMaskedEmail(response.data.masked_email);
      setRemainingAttempts(response.data.attempts_left);
      if (response.data.attempts_left <= 0 || response.data.status === 'INVALID_ATTEMPT' || response.data.status === 'EXPIRED') {
        UIUtils.showMessageOnSnackBar('Please retry logging in.');
        navigate('/');
      }
    } catch (error) {
      UIUtils.trackException('Fetch verification status', error);
      UIUtils.showMessageOnSnackBar('An error occurred. Please try again.');
      navigate('/');
    } finally {
      setIsLoading(false);
      startResendCountdown();
    }
  }, [totpUID, navigate]);

  useEffect(() => {
    fetchVerificationStatus();
  }, [fetchVerificationStatus]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendCountdown > 0 && !isResendEnabled) {
      timer = setInterval(() => {
        setResendCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (resendCountdown === 0) {
      setIsResendEnabled(true);
    }
    return () => clearInterval(timer);
  }, [resendCountdown, isResendEnabled]);

  const startResendCountdown = () => {
    setResendCountdown(60);
    setIsResendEnabled(false);
  };

  const handleResendVerificationCode = async () => {
    if (!totpUID) return;
    if (resendCount >= 2) {
      UIUtils.showMessageOnSnackBar('Maximum resend attempts reached.');
      return;
    }

    try {
      const response = await api.resendVerificationCode(totpUID);
      if (response.status === 200) {
        setResendCount((prevCount) => prevCount + 1);
        startResendCountdown();
        UIUtils.showMessageOnSnackBar('Verification code resent successfully.');
      } else {
        UIUtils.showMessageOnSnackBar('Failed to resend verification code.');
      }
    } catch (error) {
      UIUtils.trackException('Resend verification code', error);
      UIUtils.showMessageOnSnackBar('An error occurred. Please try again.');
    }
  };

  const handleVerifyCode = async () => {
    if (!totpUID) return;
    try {
      setIsLoading(true);
      setLoaderMessage('Verifying the details..');
      const lsrc = UIUtils.getCookieValue('lsrc') || 'default';
      const response = await api.verifyCode(totpUID, verificationCode, lsrc);
      
      if (response.data.status === 'VERIFIED') {
        const accessToken = response.data.access_token;
        
        // Set token in API
        api.setToken(accessToken);

        // Fetch user data
        const userData = await api.fetchUserData();
        
        // Process post login with the fetched user data
        const { redirectTo } = processPostLogin(
          userData.data,
          accessToken,
          response.data.requestor
        );

        // Navigate to the redirect URL
        navigate(redirectTo, { replace: true });
      } else if (response.data.status === 'INCORRECT_OTP') {
        setRemainingAttempts(response.data.attempts_left);
        setShowAttemptsLeft(true);
        if (response.data.attempts_left <= 0) {
          UIUtils.showMessageOnSnackBar('You have exhausted all attempts. Please try again later.');
          navigate('/');
        }
      }
    } catch (error) {
      UIUtils.trackException('Verify OTP', error);
      UIUtils.showMessageOnSnackBar('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
      setLoaderMessage('');
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleVerifyCode();
  };

  const navigateToLogin = () => {
    navigate('/login', { state: location.state });
  };

  const navigateToSignUp = () => {
    navigate('/signup');
  };

  if (isLoading) {
    return <Loader message={loaderMessage} />;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow container mx-auto">
        <div className="flex items-center justify-between pb-4 mb-4 border-b">
          <div className="flex items-center">
            <button
              onClick={() => navigate(-1)}
              className="mr-4 text-gray-600 hover:text-gray-900"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <h1 className="text-xl">Verify your email</h1>
          </div>
        </div>
        <div className="max-w-md mx-auto text-center">
          <p className="mb-6 text-secondary">A verification code has been sent to {maskedEmail}.</p>
          
          <form onSubmit={handleSubmit}>
            <PinInput
              length={6}
              onChange={(value: string) => setVerificationCode(value)}
              type="numeric"
              inputMode="number"
              inputStyle={{
                borderColor: 'var(--tw-text-secondary)',
                borderWidth: '1px',
                borderRadius: '8px',
                margin: '0 2px',
                width: '40px',
                height: '40px'
              }}
              inputFocusStyle={{
                borderColor: '#6B7280',
                borderWidth: '1px'
              }}
              autoSelect={true}
              onComplete={(value: string) => setVerificationCode(value)}
            />

            <div className="mt-6">
              <Button
                text="Verify"
                onClick={() => {}}
                type="submit"
                disabled={verificationCode.length !== 6}
                className="w-full"
              />
            </div>
          </form>

          <div className="mt-6">
            <p className="font-bold text-secondary">Didn't receive the code?</p>
            <p className="text-secondary">Check your spam or junk folder</p>
          </div>

          {showAttemptsLeft && (
            <p className="text-red-600 mt-4">
              {remainingAttempts > 0
                ? `You have entered an incorrect code. ${remainingAttempts} attempts left!`
                : 'No attempts remaining. Please try again later.'}
            </p>
          )}

          {isResendEnabled && resendCount < 2 ? (
            <Button
              text="Resend Verification Code"
              onClick={handleResendVerificationCode}
              backgroundColor="bg-white"
              textColor="text-primary"
              className="mt-4"
            />
          ) : resendCount < 2 ? (
            <p className="mt-4 text-secondary">Resend code in {resendCountdown} seconds</p>
          ) : (
            <p className="mt-4 text-secondary">
              Already have an account?{' '}
              <TextButton
                text="Login with email"
                onClick={navigateToLogin}
                className="inline-block"
              />
            </p>
          )}

          <p className="mt-4 text-secondary">
            If you have added an incorrect email address please{' '}
            <TextButton
              text="resubmit the form"
              onClick={navigateToSignUp}
              className="inline-block"
            />
          </p>
        </div>
      </main>
    </div>
  );
};

export default EmailVerification;
