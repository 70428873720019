import { BenefitsSummary } from './types';
import { NavigateFunction } from 'react-router-dom';

export const handleBenefitsNavigation = (
  benefits: BenefitsSummary | null | undefined,
  navigate: NavigateFunction
) => {
  // Check if benefits is null, undefined, or an empty object
  if (!benefits || Object.keys(benefits).length === 0) {
    navigate('/getting-started');
  } else if (benefits.stage === 'full') {
    navigate('/benefits-summary');
  } else if (benefits.stage === 'estimate') {
    navigate('/quick-results');
  } else {
    // If benefits exists but doesn't have a valid stage, default to getting-started
    navigate('/getting-started');
  }
};
