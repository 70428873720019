import React, { createContext, useContext, useState, ReactNode } from 'react';
import api from '../utils/api';
import { UserGoal, CustomerData, BenefitsSummary, BenefitsBreakdown } from '../utils/types';
import { UIUtils } from '../utils/uiUtils';

interface UserContextType {
  user: CustomerData['basic_profile'] | null;
  userGoal: UserGoal | null;
  benefitsSummary: BenefitsSummary | null;
  benefitsBreakdown: BenefitsBreakdown | null;
  marketing_email_dnc_flag: boolean;
  isConsentAccepted: boolean;
  categories: number[];
  isLoading: boolean;
  error: string | null;
  updateUserData: (data: { 
    user: any; 
    userGoal: any; 
    benefitsSummary: BenefitsSummary | null;
    benefitsBreakdown: BenefitsBreakdown | null;
    marketing_email_dnc_flag?: boolean;
  }) => void;
  updateConsent: (consent: boolean) => Promise<void>;
  clearUserData: () => void;
  setDiscountsForGoal: (goalId: number, categories?: number[]) => void;
  setSaveOnShopping: () => void;
  setCategories: (categories: number[]) => void;
}

const USER_STORAGE_KEY = 'afforda_user_data';
const TOKEN_STORAGE_KEY = 'afforda_auth_token';
const APPLIED_FILTERS_KEY = 'afforda_applied_filters';

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<Omit<UserContextType, 'updateUserData' | 'updateConsent' | 'clearUserData' | 'setDiscountsForGoal' | 'setSaveOnShopping' | 'setCategories'>>(() => {
    try {
      const storedData = localStorage.getItem(USER_STORAGE_KEY);
      const token = localStorage.getItem(TOKEN_STORAGE_KEY);
      
      if (token && !storedData) {
        return {
          user: null,
          userGoal: null,
          benefitsSummary: null,
          benefitsBreakdown: null,
          marketing_email_dnc_flag: false,
          isConsentAccepted: false,
          categories: [],
          isLoading: false,
          error: null,
        };
      }
      
      return storedData ? JSON.parse(storedData) : {
        user: null,
        userGoal: null,
        benefitsSummary: null,
        benefitsBreakdown: null,
        marketing_email_dnc_flag: false,
        isConsentAccepted: false,
        categories: [],
        isLoading: false,
        error: null,
      };
    } catch (error) {
      UIUtils.trackException('Reading from localStorage', error);
      return {
        user: null,
        userGoal: null,
        benefitsSummary: null,
        benefitsBreakdown: null,
        marketing_email_dnc_flag: false,
        isConsentAccepted: false,
        categories: [],
        isLoading: false,
        error: null,
      };
    }
  });

  const updateUserData = (data: { 
    user: any; 
    userGoal: any; 
    benefitsSummary: BenefitsSummary | null;
    benefitsBreakdown: BenefitsBreakdown | null;
    marketing_email_dnc_flag?: boolean;
  }) => {
    // Check for stored filters
    const storedFilters = localStorage.getItem(APPLIED_FILTERS_KEY);
    const categories = storedFilters ? JSON.parse(storedFilters) : userData.categories;

    const newData = {
      user: data.user,
      userGoal: data.userGoal,
      benefitsSummary: data.benefitsSummary,
      benefitsBreakdown: data.benefitsBreakdown,
      marketing_email_dnc_flag: typeof data.marketing_email_dnc_flag === 'boolean' ? data.marketing_email_dnc_flag : userData.marketing_email_dnc_flag,
      isConsentAccepted: userData.isConsentAccepted,
      categories,
      isLoading: false,
      error: null,
    };
    setUserData(newData);
    try {
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(newData));
    } catch (error) {
      UIUtils.trackException('Saving to localStorage', error);
    }
  };

  const updateConsent = async (consent: boolean) => {
    try {
      await api.capturePrivacyPolicyConsent();
      const newData = {
        ...userData,
        isConsentAccepted: consent,
      };
      setUserData(newData);
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(newData));
    } catch (error) {
      UIUtils.trackException('Update consent', error);
      throw error;
    }
  };

  const clearUserData = () => {
    const clearedData = {
      user: null,
      userGoal: null,
      benefitsSummary: null,
      benefitsBreakdown: null,
      marketing_email_dnc_flag: false,
      isConsentAccepted: false,
      categories: [],
      isLoading: false,
      error: null,
    };
    setUserData(clearedData);
    localStorage.removeItem(USER_STORAGE_KEY);
    localStorage.removeItem('afforda_form_data');
  };

  const setCategories = (categories: number[]) => {
    const newData = {
      ...userData,
      categories,
    };
    setUserData(newData);
    try {
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(newData));
    } catch (error) {
      UIUtils.trackException('Saving to localStorage', error);
    }
  };

  const setDiscountsForGoal = async (goalId: number, categories?: number[]) => {
    try {
      if (categories) {
        setCategories(categories);
      }
    } catch (error) {
      UIUtils.trackException('Set discounts for goal', error);
    }
  };

  const setSaveOnShopping = async () => {
    try {
      // Set default category to [1] for Groceries and supermarkets
      setCategories([1]);
    } catch (error) {
      UIUtils.trackException('Set saveOnShopping', error);
    }
  };

  return (
    <UserContext.Provider value={{ 
      ...userData, 
      updateUserData,
      updateConsent,
      clearUserData,
      setDiscountsForGoal,
      setSaveOnShopping,
      setCategories
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
