import React, { useState, useEffect } from 'react';
import api from '../utils/api';
import { useNavigate } from 'react-router-dom';
import { useBottomNavBar } from '../contexts/BottomNavBarContext';
import { useUser } from '../contexts/UserContext';
import styles from './PreferenceCenter.module.css';
import Loader from '../components/Loader';
import { UIUtils } from '../utils/uiUtils';

interface PreferenceCenterConfig {
  showMarketingEmail: boolean;
  showPushNotification: boolean;
  showAudioRecording: boolean;
  showLocationPermission: boolean;
}

interface PreferenceCenterProps {
  config: PreferenceCenterConfig;
}

const PreferenceCenter: React.FC<PreferenceCenterProps> = ({ config }) => {
  const [marketingEmail, setMarketingEmail] = useState(false);
  const [pushNotification, setPushNotification] = useState(false);
  const [audioRecording, setAudioRecording] = useState(false);
  const [locationPermission, setLocationPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const navigate = useNavigate();
  const { setIsVisible } = useBottomNavBar();
  const { updateUserData, user, userGoal, benefitsSummary, benefitsBreakdown, marketing_email_dnc_flag } = useUser();

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, [setIsVisible]);

  useEffect(() => {
    // Initialize marketing email preference from UserContext using the correct property
    if (marketing_email_dnc_flag !== undefined) {
      setMarketingEmail(!marketing_email_dnc_flag);
    }
  }, [marketing_email_dnc_flag]);

  const togglePreference = async (
    title: string,
    preference: string,
    value: boolean
  ) => {
    try {
      switch (preference) {
        case 'marketingEmail':
          setIsLoading(true);
          setLoadingMessage(`Updating ${title} preference...`);
          await api.updateMarketingConsent(!value);
          setMarketingEmail(value);
          // Update UserContext with new marketing_email_dnc_flag
          updateUserData({
            user,
            userGoal,
            benefitsSummary,
            benefitsBreakdown,
            marketing_email_dnc_flag: !value
          });
          break;
        case 'pushNotification':
          setPushNotification(value);
          break;
        case 'audioRecording':
          setAudioRecording(value);
          break;
        case 'locationPermission':
          setLocationPermission(value);
          break;
      }
      // Show success message
      const notification = document.createElement('div');
      notification.className = `${styles.notification} ${styles.success}`;
      notification.textContent = `Updated ${title} preferences`;
      document.body.appendChild(notification);
      setTimeout(() => notification.remove(), 3000);
    } catch (error) {
      UIUtils.trackException('Update preference', error);
      // Show error message
      const notification = document.createElement('div');
      notification.className = `${styles.notification} ${styles.error}`;
      notification.textContent = 'Error updating preference. Please try again.';
      document.body.appendChild(notification);
      setTimeout(() => notification.remove(), 3000);
    } finally {
      if (preference === 'marketingEmail') {
        setIsLoading(false);
        setLoadingMessage('');
      }
    }
  };

  const PreferenceSwitch = ({
    title,
    description,
    value,
    preference,
  }: {
    title: string;
    description: string;
    value: boolean;
    preference: string;
  }) => (
    <div className="mb-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-bold flex-grow">{title}</h3>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            name="preference"
            className="sr-only peer"
            checked={value}
            onChange={(e) => togglePreference(title, preference, e.target.checked)}
          />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer 
                        peer-checked:after:translate-x-full peer-checked:after:border-white 
                        after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
                        after:bg-white after:border-gray-300 after:border after:rounded-full 
                        after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
        </label>
      </div>
      <p className={`text-sm font-bold ${value ? 'text-green-600' : 'text-red-600'}`}>
        {value ? 'You are currently opted in.' : 'You are currently opted out.'}
      </p>
      <p className="text-sm mt-1">{description}</p>
    </div>
  );

  return (
    <div className="min-h-screen flex flex-col">
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Loader message={loadingMessage} />
        </div>
      )}
      <div className="flex items-center justify-between pb-4 border-b">
        <div className="flex items-center">
          <button
            onClick={() => navigate(-1)}
            className="mr-4 text-gray-600 hover:text-gray-900"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <h1 className="text-xl">Profile Settings</h1>
        </div>
      </div>
      <main className="flex-grow pt-4 max-w-4xl mx-auto w-full">
        <div className="bg-surface-light border-l-4 border-outline p-4 mb-5">
          <p className="text-base">
            Easily manage your profile settings anytime by toggling the switches below.
          </p>
        </div>

        {config.showMarketingEmail && (
          <PreferenceSwitch
            title="Marketing Email"
            description="Opt in to receive Afforda's promotional emails with updates on exclusive retail discounts, information about government benefits, and more."
            value={marketingEmail}
            preference="marketingEmail"
          />
        )}

        {config.showPushNotification && (
          <PreferenceSwitch
            title="Push Notifications"
            description="Enable push notifications for personalised tips and reminders which may help you reach your financial goals."
            value={pushNotification}
            preference="pushNotification"
          />
        )}

        {config.showAudioRecording && (
          <PreferenceSwitch
            title="Audio Recording & Speech-to-Text"
            description="Enable voice input to describe your financial needs by speaking instead of typing, making it easier to find solutions."
            value={audioRecording}
            preference="audioRecording"
          />
        )}

        {config.showLocationPermission && (
          <PreferenceSwitch
            title="Location Permission"
            description="Allow location access to get timely reminders about savings and discounts near participating outlets, helping you make the most of Afforda's exclusive offers while shopping."
            value={locationPermission}
            preference="locationPermission"
          />
        )}
      </main>
    </div>
  );
};

export default PreferenceCenter;
