import { useEffect } from 'react';
import { useSnackbar } from '../components/SnackbarProvider';
import { setSnackbarShowMessage } from '../utils/uiUtils';

export const useInitSnackbar = () => {
  const { showMessage } = useSnackbar();
  useEffect(() => {
    setSnackbarShowMessage(showMessage);
  }, [showMessage]);
};
