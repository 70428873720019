import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { useAuth } from '../utils/auth';
import { Button } from '../components/Button';
import config from '../configs/config';
import { UIUtils } from '../utils/uiUtils';

const PrivacyConsent: React.FC = () => {
  const [hasClickedPolicyLink, setHasClickedPolicyLink] = useState(false);
  const { updateConsent } = useUser();
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleConsent = async () => {
    try {
      await updateConsent(true);
      navigate('/customer-home');
    } catch (error) {
      UIUtils.trackException('Update privacy consent', error);
    }
  };

  const handleDecline = async () => {
    await signOut();
    window.location.href = '/';
  };

  return (
    <div className="min-h-screen">
          <div className="bg-white rounded-lg border-2 border-primary py-6 px-2 md:px-6">
            <h2 className="text-xl font-bold text-center mb-6">
              We've updated our Privacy Policy
            </h2>

            <div className="bg-surface-light border-l-4 border-outline p-4 mb-6">
              <p className="text-base">
                We'd like you to review the policies below, and if you're happy, click on I consent. 
                If you select No thanks, we will pause access to your Afforda account. 
                Thank you for your understanding.
              </p>
            </div>

            <div className="flex items-start space-x-3 mb-6">
              <input
                type="checkbox"
                name="privacy_consent"
                checked={hasClickedPolicyLink}
                onChange={(e) => setHasClickedPolicyLink(e.target.checked)}
                className="mt-1"
              />
              <p className="text-base">
                I agree to the{' '}
                <a
                  href={`${config.WORDPRESS_URL}/terms-of-use`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setHasClickedPolicyLink(true)}
                  className="text-primary font-semibold underline"
                >
                  Terms of Use
                </a>
                {' '}and consent to the{' '}
                <a
                  href={`${config.WORDPRESS_URL}/privacy-policy`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setHasClickedPolicyLink(true)}
                  className="text-primary font-semibold underline"
                >
                  Privacy Policy
                </a>
                , which explains how my personal information will be processed and shared. 
                This includes any sensitive information I may choose to provide, such as information about my health, 
                and I'm aware that I can withdraw this consent at any time.
              </p>
            </div>

            <div className="flex justify-center space-x-4">
              <Button
                text="No thanks"
                onClick={handleDecline}
                backgroundColor="bg-white"
                textColor="text-primary"
                className="border border-primary hover:bg-secondaryBtn-hover"
              />
              <Button
                text="I consent"
                onClick={handleConsent}
                disabled={!hasClickedPolicyLink}
                backgroundColor={hasClickedPolicyLink ? undefined : 'bg-gray-400'}
                className={!hasClickedPolicyLink ? 'cursor-not-allowed' : ''}
              />
            </div>
          </div>
        </div>
  );
};

export default PrivacyConsent;
