import { AppConfig } from "./types";

const config: AppConfig = {
  API_URL_BASE: 'https://api.afforda.co.uk',
  WORDPRESS_URL: 'https://afforda.co.uk',
  LS_URL: 'https://www.lendingstream.co.uk',
  GOOGLE_CLIENT_ID: '386269864544-srb0qi97tdhmllenq4sjhpur258pd94f.apps.googleusercontent.com',
  APPLE_SERVICE_ID: 'com.gaincredit.afforda.web.production',
  ENV: 'production',
  LEAD_LANDING_TOKEN: '219fdcd7db40a994ea41a4c12827832fb445074079ee8b69701309332ce8899f'
};

export default config;
