import Cookies from 'js-cookie';

// Utility function to fetch query parameters
const fetchQueryParam = (param: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

// Function to compute Ads Source Lsrc
const computeAdsSourceLsrc = (): string => {
  let adsLsrc = '';
  const gadSource = fetchQueryParam('gad_source');
  const gbraid = fetchQueryParam('gbraid');
  const utmQuery = [gadSource, gbraid];
  const nonEmptyValues = utmQuery.filter(value => value !== null);
  if (nonEmptyValues.length > 0) {
    adsLsrc = nonEmptyValues.join('_') + '_gads';
  }
  return adsLsrc;
};

// Function to compute UTM Source Lsrc
const computeUtmSourceLsrc = (): string => {
  let utmLsrc = '';
  const utmSource = fetchQueryParam('utm_source');
  const utmMedium = fetchQueryParam('utm_medium');
  const utmCampaign = fetchQueryParam('utm_campaign');
  const utmContent = fetchQueryParam('utm_content');
  const utmTerm = fetchQueryParam('utm_term');
  const utmQuery = [utmSource, utmMedium, utmCampaign, utmContent, utmTerm];
  const nonEmptyValues = utmQuery.filter(value => value !== null);
  if (nonEmptyValues.length > 0) {
    utmLsrc = nonEmptyValues.join('_') + '_utm';
  }
  return utmLsrc;
};

// Function to compute Referrer Source Lsrc
const computeReferrerSourceLsrc = (): string => {
  const referrer = document.referrer;
  const hostname = window.location.hostname;
  let sourceLsrc = '';
  if (referrer && !referrer.includes(hostname)) {
    sourceLsrc = 'afforda_organic';
  } else if (!referrer) {
    sourceLsrc = 'afforda_direct';
  }
  return sourceLsrc;
};

// Function to set Lsrc and History
const setLsrcAndHistory = (lsrcValue: string): boolean => {
  const lsrcCookie = Cookies.get('lsrc') || '';
  if (!lsrcValue) {
    return false;
  }
  if ((lsrcCookie === 'afforda_organic' || (lsrcCookie && lsrcCookie.includes('_utm')) || (lsrcCookie && lsrcCookie.includes('_gads'))) && lsrcValue === 'afforda_direct') {
    return false;
  }
  let hostname = window.location.hostname;
  if (hostname === 'afforda.co.uk') {
    hostname = '.afforda.co.uk';
  }
  if (!lsrcCookie || lsrcCookie !== lsrcValue) {
    Cookies.set('lsrc', lsrcValue, { expires: 60, domain: hostname });
    const datetime = new Date().toISOString();
    const lsrcHistory = Cookies.get('lsrc_history') || '';
    Cookies.set('lsrc_history', lsrcHistory ? `${lsrcHistory}|${lsrcValue}:${datetime}` : `${lsrcValue}:${datetime}`, { expires: 60, domain: hostname });
  }
  return true;
};

// Main function to set Lsrc Cookies
export const calculateLeadSource = (): void => {
  let lsrcValue = computeAdsSourceLsrc();
  if (!lsrcValue) {
    lsrcValue = computeUtmSourceLsrc();
  }
  if (!lsrcValue) {
    lsrcValue = computeReferrerSourceLsrc();
  }
  if (lsrcValue) {
    setLsrcAndHistory(lsrcValue);
  }
};