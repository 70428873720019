import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { processOneClickUnsubscribe } from '../utils/api';
import { Button } from '../components/Button';
import { UIUtils } from '../utils/uiUtils';

const Unsubscribe: React.FC = () => {
  const { customerUID } = useParams<{ customerUID: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);

  useEffect(() => {
    const processUnsubscribe = async () => {
      try {
        if (!customerUID) {
          throw new Error('Customer ID is required');
        }
        const source = searchParams.get('utm_source') || 'one-click-unsub';
        await processOneClickUnsubscribe(customerUID, source);
        setIsUnsubscribed(true);
      } catch (error) {
        setIsError(true);
        setErrorMessage('We were unable to process your unsubscribe request automatically.');
      } finally {
        setIsLoading(false);
      }
    };

    processUnsubscribe();
  }, [customerUID, searchParams]);

  // Separate useEffect for logging
  useEffect(() => {
    if (isUnsubscribed) {
      UIUtils.trackInput('is_opted_in', isUnsubscribed ? 'true' : 'false', false);
    }
  }, [isUnsubscribed]);

  return (
    <>
      <div className="px-4">
        <div className="max-w-lg mx-auto mt-8">
          {isLoading ? (
            <div className="text-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary mx-auto"></div>
              <p className="mt-4 text-lg">Processing your unsubscribe request</p>
            </div>
          ) : (
            <div className="text-center">
              {isError ? (
                <>
                  <div className="text-error mb-4">
                    <svg
                      className="w-12 h-12 mx-auto"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <p className="text-lg mb-6">{errorMessage}</p>
                  <p className="text-base mb-4">
                    Please log in to your account to update your email preferences:
                  </p>
                  <ol className="text-left text-base mb-6 space-y-2">
                    <li>1. Click the button below to go to the login page</li>
                    <li>2. Log in with your email address</li>
                    <li>3. Navigate to Profile Settings</li>
                    <li>4. Update your email marketing preferences</li>
                  </ol>
                  <Button
                    text="Go to Login"
                    onClick={() => navigate('/login')}
                  />
                </>
              ) : (
                <>
                  <div className="text-success mb-4">
                    <svg
                      className="w-12 h-12 mx-auto"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <p className="text-lg mb-4">
                    You have been successfully unsubscribed from marketing emails.
                  </p>
                  <p className="text-base text-primary-muted">
                    You can opt back in at any time by logging in and updating your email preferences
                    in your profile settings.
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Unsubscribe;
