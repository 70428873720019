import React, { useState } from 'react';

interface SectionProps {
  isDone: boolean;
  isExpanded: boolean;
  sectionHeader: string;
  sectionText: React.ReactNode;
  ctaText?: string;
  subText?: React.ReactNode;
  onPressed: () => void;
  defaultExpanded?: boolean;
}

const Section: React.FC<SectionProps> = ({
  isDone,
  isExpanded,
  sectionHeader,
  sectionText,
  ctaText,
  subText,
  onPressed,
  defaultExpanded = false,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!defaultExpanded);

  return (
    <div className="border border-gray-200 rounded-lg">
      <div 
        className="p-4 flex justify-between items-center cursor-pointer hover:bg-gray-50"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <h3 className="text-lg font-semibold">{sectionHeader}</h3>
        <span className="text-lg text-gray-500 font-medium">{isCollapsed ? '+' : '−'}</span>
      </div>
      
      <div className={`transition-all duration-300 ease-in-out ${
        isCollapsed ? 'max-h-0 opacity-0 overflow-hidden' : 'max-h-[500px] opacity-100'
      }`}>
        <div className="px-4 pb-4 flex flex-col gap-3">
          <div className="text-gray-600">{sectionText}</div>
          {ctaText && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onPressed();
              }}
              className={`w-full py-3 px-4 rounded-2xl text-center font-medium transition-colors duration-200 ${
                isDone
                  ? 'bg-green-100 text-green-700'
                  : 'bg-primary text-white hover:bg-primary/90 active:bg-primary/80 disabled:opacity-50'
              }`}
              disabled={isDone}
            >
              {isDone ? 'Completed' : ctaText}
            </button>
          )}
          {subText && <div className="text-sm text-gray-600 font-bold">{subText}</div>}
        </div>
      </div>
    </div>
  );
};

export default Section;
