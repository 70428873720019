import { AppConfig } from "./types";

const config: AppConfig = {
  API_URL_BASE: 'https://api.afforda.global-analytics.in',
  WORDPRESS_URL: 'https://staging-90a5-moneasycouk.wpcomstaging.com',
  LS_URL: 'https://www.lendingstream.co.uk',
  GOOGLE_CLIENT_ID: '386269864544-laugptg9t8tvqgegmjmk8co860gf4qpb.apps.googleusercontent.com',
  APPLE_SERVICE_ID: 'com.gaincredit.afforda.web.staging',
  ENV: 'staging',
  LEAD_LANDING_TOKEN: '53d929152954d10c06dbed79be74ddfadd22485e90075871cd89ff06139188bd'
};

export default config;
