import React from 'react';

interface LoaderProps {
  message?: string;
}

const Loader: React.FC<LoaderProps> = ({ message }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full bg-white">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-secondary mb-4"></div>
      {message && (
        <p className="text-secondary text-center px-4">{message}</p>
      )}
    </div>
  );
};

export default Loader;
