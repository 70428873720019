import React, { useState } from 'react';
import { Resources, UseOfFundsValue } from '../../utils/resources';
import { useForm } from '../../contexts/FormContext';
import { MdLock } from 'react-icons/md';

const DEFAULT_LOAN_AMOUNT = 500;

const Step1: React.FC = () => {
  const { formData, updateField, validateField, isFieldTouched, touchedFields } = useForm();
  const [loanAmount, setLoanAmount] = useState<number>(
    Number(formData.borrow_amount) || DEFAULT_LOAN_AMOUNT
  );

  const handleAmountChange = (value: number) => {
    setLoanAmount(value);
    updateField('borrow_amount', value.toString());
  };

  const handleUseOfFundsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as UseOfFundsValue;
    updateField('use_of_funds', value);
  };

  // Show errors if field is touched or if form submission was attempted
  const amountError = (isFieldTouched('borrow_amount') || touchedFields['borrow_amount'])
    ? validateField('borrow_amount', loanAmount.toString())
    : null;

  const useOfFundsError = (isFieldTouched('use_of_funds') || touchedFields['use_of_funds'])
    ? validateField('use_of_funds', formData.use_of_funds || '')
    : null;

  return (
    <div className="flex flex-col space-y-6">
      <div>
        <h1 className="text-2xl font-bold">
          Let us help you find a quote
        </h1>
        <p className="text-gray-600 mt-2">
          No obligation, quick and simple process
        </p>
      </div>

      {/* Alert Box */}
      <div className="bg-secondaryBtn-hover rounded-lg p-4 flex items-center space-x-3">
        <MdLock className="h-6 w-6 text-primary" />
        <p className="text-gray-800">
          Getting a quote from a lender won't affect your credit score
        </p>
      </div>

      {/* Loan Amount Input */}
      <div className="space-y-4">
        <label className="block text-sm font-medium text-gray-700">
          How much do you need to borrow?
        </label>
        <div className="relative mt-1">
          <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
            £
          </span>
          <input
            type="number"
            name="loan_amount"
            value={loanAmount}
            onChange={(e) => handleAmountChange(Number(e.target.value))}
            className={`block w-full pl-7 pr-12 py-2 border ${
              amountError ? 'border-red-500' : 'border-gray-300'
            } rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500`}
            min={100}
            max={5000}
            aria-invalid={!!amountError}
            aria-describedby={amountError ? "amount-error" : undefined}
          />
        </div>
        {amountError && (
          <p className="mt-2 text-sm text-red-600" id="amount-error" role="alert">
            {amountError}
          </p>
        )}

        {/* Slider */}
        <div className="space-y-2">
          <input
            type="range"
            name="loan_amount_slider"
            min={100}
            max={5000}
            step={10}
            value={loanAmount}
            onChange={(e) => handleAmountChange(Number(e.target.value))}
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
          />
          <div className="flex justify-between text-sm text-gray-600">
            <span>£100</span>
            <span>£5000</span>
          </div>
        </div>
      </div>

      {/* Use of Funds Dropdown */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">
          What will you use this money for?
        </label>
        <select
          value={formData.use_of_funds || ''}
          name="use_of_funds"
          onChange={handleUseOfFundsChange}
          className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border ${
            useOfFundsError ? 'border-red-500' : 'border-gray-300'
          } focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md`}
          aria-invalid={!!useOfFundsError}
          aria-describedby={useOfFundsError ? "use-of-funds-error" : undefined}
        >
          <option value="">Select a purpose</option>
          {Object.entries(Resources.useOfFunds).map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        {useOfFundsError && (
          <p className="mt-2 text-sm text-red-600" id="use-of-funds-error" role="alert">
            {useOfFundsError}
          </p>
        )}
      </div>
    </div>
  );
};

export default Step1;
