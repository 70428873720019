import React, { useState, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import { useBottomNavBar } from '../contexts/BottomNavBarContext';
import UserGoalInput from '../components/UserGoalInput';
import Loader from '../components/Loader';

const UserGoal: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();
  const { setIsVisible } = useBottomNavBar();
  const firstName = user?.first_name || '';

  useEffect(() => {
    setIsVisible(true); // Show bottom nav bar when component mounts
    return () => setIsVisible(false); // Hide bottom nav bar when component unmounts
  }, [setIsVisible]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white">
        <Loader message="Afforda is working to solve your problem..." />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-white pb-20">
      <main className="flex-1 container mx-auto">
        <div className="max-w-2xl mx-auto">
          <h1 className="text-xl font-bold mb-2">Hello {firstName},</h1>
          <p className="text-base mb-2">
            We're here to help you better afford the things you need.
          </p>
          <p className="text-base mb-6">
            We would like to understand what has brought you to Afforda today.
          </p>

          <UserGoalInput
            onLoadingStateChanged={setIsLoading}
            initialPrompt="The more specific you can be, the better. This helps Afforda to give you more options."
            examples={[
              "I'm moving house and need help with moving costs and getting settled in",
              "My washing machine is broken, and I can't afford a new one",
              "My rent is due and I'm short on money this month",
            ]}
          />
        </div>
      </main>
    </div>
  );
};

export default UserGoal;
