import axios, { AxiosInstance } from 'axios';
import config from '../configs/config';
import { UIUtils } from './uiUtils';

interface MenuItem {
  title: string;
  url: string;
}

interface MenuItems {
  [key: string]: MenuItem[];
}

class WordPressApi {
  private static instance: WordPressApi;
  private api: AxiosInstance;
  private cache: MenuItems = {};

  private constructor() {
    this.api = axios.create({
      baseURL: config.WORDPRESS_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public static getInstance(): WordPressApi {
    if (!WordPressApi.instance) {
      WordPressApi.instance = new WordPressApi();
    }
    return WordPressApi.instance;
  }

  private parentTitles = ['Loans', 'Personal Finance', 'Benefits'];

  public async fetchSubMenuItems(parentTitle: string): Promise<MenuItem[]> {
    if (this.cache[parentTitle]) {
      return this.cache[parentTitle];
    }

    try {
      const response = await this.api.get(`/wp-json/v1/fetch_child_pages/?parent_title=${parentTitle}`);
      const items: MenuItem[] = response.data.map((item: any) => ({
        title: item.title,
        url: item.url,
      }));

      this.cache[parentTitle] = items;
      return items;
    } catch (error) {
      UIUtils.trackException(`Fetch sub-menu for ${parentTitle}`, error);
      return [];
    }
  }

  public async fetchAllSubMenuItems(): Promise<MenuItems> {
    const subMenuItems: MenuItems = {};

    // Use Promise.all to fetch all sub-menu items in parallel
    const fetchPromises = this.parentTitles.map(async (title) => {
      subMenuItems[title] = await this.fetchSubMenuItems(title);
    });

    await Promise.all(fetchPromises);

    return subMenuItems;
  }
}

const wordpressApi = WordPressApi.getInstance();
export default wordpressApi;
