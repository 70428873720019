import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { useBottomNavBar } from '../contexts/BottomNavBarContext';
import { UIUtils } from '../utils/uiUtils';
import api from '../utils/api';

const FullBenefits: React.FC = () => {
  const navigate = useNavigate();
  const { benefitsSummary, user, userGoal, marketing_email_dnc_flag, updateUserData } = useUser();
  const { setIsVisible } = useBottomNavBar();

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, [setIsVisible]);

  useEffect(() => {
    // Set up message listener for iframe completion
    const handleMessage = async (event: MessageEvent) => {
      // Handle both string and object message types
      let status: string;
      if (typeof event.data === 'string') {
        status = 'undefined';
      } else {
        status = event.data?.type || 'default_value';
      }

      if (status === 'complete') {
        try {
          // Call the API to notify completion
          const response = await api.postFullBenefitsCompletion();
          
          // Process the benefits completion response
          const { benefitsBreakdown, benefitsSummary: newBenefitsSummary } = UIUtils.processBenefitsCompletion(response.data);
          
          // Update the user context with new benefits data while preserving other data
          updateUserData({
            user: user,
            userGoal: userGoal,
            benefitsSummary: newBenefitsSummary,
            benefitsBreakdown: benefitsBreakdown,
            marketing_email_dnc_flag: marketing_email_dnc_flag
          });

          // Navigate to customer home
          navigate('/benefits-summary');
        } catch (error) {
          UIUtils.trackException('Handle benefits completion', error);
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [navigate, updateUserData, user, userGoal, marketing_email_dnc_flag]);

  if (!benefitsSummary?.iframeUrl) {
    return null;
  }

  const iframeUrl = `${benefitsSummary.iframeUrl}&short=false&edit=true&redirectParent=true`;

  return (
    <div className="flex flex-col h-screen">
      <div className="flex items-center justify-between pb-4 border-b">
        <div className="flex items-center">
          <button
            onClick={() => navigate(-1)}
            className="mr-4 text-gray-600 hover:text-gray-900"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <h1 className="text-xl">Benefits Calculator</h1>
        </div>
      </div>

      <div className="flex-1 relative">
        <div className="absolute inset-0">
          <iframe
            src={iframeUrl}
            className="w-full h-full border-0"
            title="Benefits Calculator"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          />
        </div>
      </div>
    </div>
  );
};

export default FullBenefits;
