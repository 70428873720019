import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../utils/api';
import { UIUtils } from '../utils/uiUtils';
import { useBottomNavBar } from '../contexts/BottomNavBarContext';
import Loader from '../components/Loader';
import { Button } from '../components/Button';
import SocialSignIn from '../components/SocialSignIn';

interface LoginProps {
  initialEmail?: string;
}

const Login: React.FC<LoginProps> = ({ initialEmail = '' }) => {
  const [email, setEmail] = useState(initialEmail);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsVisible } = useBottomNavBar();

  useEffect(() => {
    setIsVisible(false);
    return () => setIsVisible(true);
  }, [setIsVisible]);

  useEffect(() => {
    // Check for email in location state (from signup redirect)
    const stateEmail = location.state?.email;
    if (stateEmail) {
      setEmail(stateEmail);
      return;
    }
    // Fall back to URL params if no state email
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [location]);

  const sendVerificationEmail = async () => {
    setIsLoading(true);
    try {
      const response = await api.postEmailForVerification(email);
      const status = response.data.status;
      UIUtils.trackFormSubmit(status,"");
      if (status === 'PENDING_VERIFICATION') {
        const referenceId = response.data.reference_id;
        // Pass the location state to email verification
        navigate(`/email-verification/${referenceId}`, { 
          state: { 
            from: location.state?.from || '/customer-home',
            email: email 
          } 
        });
      } else if (status === 'USER_NOT_FOUND') {
        UIUtils.showMessageOnSnackBar('User not found. Please check the email entered.');
      } else {
        UIUtils.showMessageOnSnackBar('An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      UIUtils.trackException('Send verification email', error);
      UIUtils.showMessageOnSnackBar('Error sending verification email. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  // Determine if user was redirected from signup
  const isRedirectFromSignup = location.state?.email != null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    sendVerificationEmail();
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex items-center justify-between pb-4 border-b">
        <div className="flex items-center">
          <button
            onClick={() => navigate(-1)}
            className="mr-4 text-gray-600 hover:text-gray-900"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <h1 className="text-xl">Welcome back</h1>
        </div>
      </div>
      <main className="flex-grow container mx-auto mt-4">
        <div className="max-w-md mx-auto bg-white rounded-lg shadow-md p-6">
          {isLoading ? (
            <Loader message="Signing in..." />
          ) : (
            <>
              {isRedirectFromSignup && (
                <div className="mb-6 p-4 bg-secondaryBtn-hover text-primary rounded-lg">
                  An account already exists with this email address. Please login to continue using Afforda.
                </div>
              )}
              <p className="text-center mb-6 text-lg text-secondary">
                Enter your email to receive a verification code.
              </p>
              <form onSubmit={handleSubmit} className="mb-8">
                <div className="mb-6">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input
                    data-sensitive='true'
                    id="email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-input"
                    placeholder="Email"
                    aria-label="Email"
                  />
                </div>
                <Button
                  text="Send Verification Code"
                  onClick={() => { }} // Empty function since form handles submission
                  type="submit"
                  disabled={email.length === 0}
                  className="w-full"
                  aria-label="Send Verification Code"
                />
              </form>
              <SocialSignIn 
                onLoadingChange={setIsLoading} 
                redirectPath={location.state?.from}
              />
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default Login;
