import React from 'react';
import { useForm } from '../../contexts/FormContext';
import { Resources } from '../../utils/resources';
import { useUser } from '../../contexts/UserContext';
import { Link } from 'react-router-dom';

interface Step8Props {
  onStepChange: (step: number) => void;
}

const Step8: React.FC<Step8Props> = ({ onStepChange }) => {
  const { formData, updateField, validateField, isFieldTouched, touchedFields } = useForm();
  const { marketing_email_dnc_flag } = useUser();

  const transformFirstWord = (input: string): string => {
    if (!input) return '';
    if (input === 'Pay off another loan') return 'paying off another loan';

    const words = input.split(' ');
    if (words.length === 0) return input;

    words[0] = words[0].charAt(0).toLowerCase() + words[0].slice(1);
    return words.join(' ');
  };

  const getLoanPurposeLabel = (value: string): string => {
    const entry = Object.entries(Resources.useOfFunds).find(([_, v]) => v === value);
    return entry ? entry[0] : 'miscellaneous expenses';
  };

  const calculateTotalExpenses = (): number => {
    const expenses = [
      'rent',
      'credit_commitments',
      'utility_bills',
      'transport',
      'food_drink',
      'other_expenses'
    ];
    return expenses.reduce((total, field) => {
      return total + (Number(formData[field]) || 0);
    }, 0);
  };

  const loanPurpose = transformFirstWord(getLoanPurposeLabel(formData.use_of_funds || ''));
  const totalExpenses = calculateTotalExpenses();

  // Show errors if field is touched or if form submission was attempted
  const expenseCheckboxError = (isFieldTouched('checkbox_expense') || touchedFields['checkbox_expense'])
    ? validateField('checkbox_expense', formData.checkbox_expense || '')
    : null;

  const privacyConsentError = (isFieldTouched('consent_privacy') || touchedFields['consent_privacy'])
    ? validateField('consent_privacy', formData.consent_privacy || '')
    : null;

  const handleCheckboxChange = (type: 'expense' | 'privacy' | 'marketing') => (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    
    // Map checkbox types to form field names
    const fieldMap = {
      expense: 'checkbox_expense',
      privacy: 'consent_privacy',
      marketing: 'marketing_email_optin_flag'
    };

    // Update form data
    updateField(fieldMap[type], checked.toString());
  };

  const summaryItems = [
    {
      title: `£${formData.borrow_amount} for ${loanPurpose}`,
      step: 0
    },
    {
      title: `${formData.first_name} ${formData.last_name}`,
      step: 1
    },
    {
      title: formData.postcode,
      step: 2
    },
    {
      title: `Income: £${formData.income_amount}`,
      step: 5
    },
    {
      title: `Expenses: £${totalExpenses}`,
      step: 6
    }
  ];

  return (
    <div className="flex flex-col space-y-6">
      <div>
        <h1 className="text-2xl font-bold">
          Almost there...
        </h1>
        <p className="text-gray-600 mt-2">
          Confirm your application details are correct
        </p>
      </div>

      {/* Summary Cards */}
      <div className="space-y-4">
        {summaryItems.map((item, index) => (
          <div
            key={index}
            onClick={() => onStepChange(item.step)}
            className="bg-white rounded-lg shadow p-4 cursor-pointer hover:shadow-md transition-shadow"
          >
            <div className="flex justify-between items-center">
              <span>{item.title}</span>
              <span className="text-blue-500">edit</span>
            </div>
          </div>
        ))}
      </div>

      {/* Confirmation Checkboxes */}
      <div className="space-y-4">
        <div className="space-y-2">
          <label className={`flex items-start space-x-2 ${expenseCheckboxError ? 'text-red-600' : ''}`}>
            <input
              type="checkbox"
              name="confirm_expense"
              checked={formData.checkbox_expense === 'true'}
              onChange={handleCheckboxChange('expense')}
              className={`mt-1 ${expenseCheckboxError ? 'border-red-500' : ''}`}
              aria-invalid={!!expenseCheckboxError}
              aria-describedby={expenseCheckboxError ? "expense-checkbox-error" : undefined}
            />
            <span className="text-sm text-secondary">
              I confirm that the income and expenses that I have provided are correct.
            </span>
          </label>
          {expenseCheckboxError && (
            <p className="mt-1 text-sm text-red-600" id="expense-checkbox-error" role="alert">
              {expenseCheckboxError}
            </p>
          )}
        </div>

        <div className="space-y-2">
          <label className={`flex items-start space-x-2 ${privacyConsentError ? 'text-red-600' : ''}`}>
            <input
              type="checkbox"
              name="consent_privacy"
              checked={formData.consent_privacy === 'true'}
              onChange={handleCheckboxChange('privacy')}
              className={`mt-1 ${privacyConsentError ? 'border-red-500' : ''}`}
              aria-invalid={!!privacyConsentError}
              aria-describedby={privacyConsentError ? "privacy-consent-error" : undefined}
            />
            <span className="text-sm text-secondary">
              I understand that by proceeding, my data will be shared with Afforda's panel of lenders who may conduct a soft credit search. This search will not impact your credit score.
            </span>
          </label>
          {privacyConsentError && (
            <p className="mt-1 text-sm text-red-600" id="privacy-consent-error" role="alert">
              {privacyConsentError}
            </p>
          )}
        </div>

        {/* Only show marketing opt-in if user hasn't opted in (marketing_email_dnc_flag is true) */}
        {marketing_email_dnc_flag && (
          <label className="flex items-start space-x-2">
            <input
              type="checkbox"
              name="marketing_email_optin_flag"
              checked={formData.marketing_email_optin_flag === 'true'}
              onChange={handleCheckboxChange('marketing')}
              className="mt-1"
            />
            <span className="text-sm text-secondary">
              We would like to keep you updated on loan offers, products and services we offer through Gain Credit LLC (trading as Afforda) by email. You can unsubscribe at any time by going to your{' '}
              <Link to="/preference-center" className="text-blue-500 hover:underline">
                profile settings
              </Link>
              .
            </span>
          </label>
        )}
      </div>
    </div>
  );
};

export default Step8;
