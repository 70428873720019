import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import api from '../utils/api';
import { UIUtils } from '../utils/uiUtils';

interface FormContextType {
  formData: { [key: string]: string };
  touchedFields: { [key: string]: boolean };
  updateField: (key: string, value: string) => void;
  validateField: (key: string, value: string) => string | null;
  isFieldTouched: (key: string) => boolean;
  markFieldsAsTouched: (keys: string[]) => void;
  validateForm: () => boolean;
  validatePageFields: (fields: string[]) => boolean;
  submitForm: () => Promise<any>;
  submitShortForm: () => Promise<any>;
  clearTouchedFields: () => void;
}

interface FormData {
  [key: string]: string | boolean;
}

const FORM_STORAGE_KEY = 'afforda_form_data';

const FormContext = createContext<FormContextType | undefined>(undefined);

interface FieldValidator {
  required?: boolean;
  enumValues?: string[];
  pattern?: string;
  min?: number;
  max?: number;
  requiredErrorMessage?: string;
  invalidErrorMessage?: string;
  validationType?: string;
  minLength?: number;
}

const validators: { [key: string]: FieldValidator } = {
  borrow_amount: {
    validationType: 'isNumber',
    min: 100,
    max: 5000,
    requiredErrorMessage: 'Please enter a valid amount',
    invalidErrorMessage: 'Please enter an amount between £100 and £5000'
  },
  use_of_funds: {
    validationType: 'required',
    requiredErrorMessage: 'Please select a reason',
    invalidErrorMessage: 'Please select a reason'
  },
  title: {
    validationType: 'required',
    requiredErrorMessage: 'Please select your title',
    invalidErrorMessage: 'Please select your title'
  },
  first_name: {
    validationType: 'isName',
    requiredErrorMessage: 'Please enter your first name',
    invalidErrorMessage: 'Please enter a valid name'
  },
  last_name: {
    validationType: 'isName',
    requiredErrorMessage: 'Please enter your last name',
    invalidErrorMessage: 'Please enter a valid name'
  },
  dob: {
    validationType: 'required',
    requiredErrorMessage: 'Please enter a valid date of birth',
    invalidErrorMessage: 'Please enter a valid date of birth'
  },
  mobile_number: {
    validationType: 'pattern',
    pattern: '(^7[0-9]\\d{8}$)|(^(44|\\+44)(7)(\\d{9})$)|(^(044|\\+044)(7)(\\d{9})$)|(^(0)(7)\\d{9}$)',
    requiredErrorMessage: 'Please enter your mobile number',
    invalidErrorMessage: 'Please enter a valid mobile number'
  },
  postcode: {
    validationType: 'isPostcode',
    requiredErrorMessage: 'Please enter your postcode',
    invalidErrorMessage: 'Please enter a valid postcode'
  },
  house_name: {
    validationType: 'nonEmpty',
    requiredErrorMessage: 'Please enter your house name/number',
    invalidErrorMessage: 'Please enter your house name/number'
  },
  street: {
    validationType: 'isName',
    requiredErrorMessage: 'Please enter your street',
    invalidErrorMessage: 'Please enter a valid street name'
  },
  post_town: {
    validationType: 'isName',
    requiredErrorMessage: 'Please enter your post town',
    invalidErrorMessage: 'Please enter a valid post town'
  },
  time_at_address: {
    validationType: 'required',
    requiredErrorMessage: 'Please select how long you have lived here',
    invalidErrorMessage: 'Please select how long you have lived here'
  },
  type_of_housing: {
    validationType: 'required',
    requiredErrorMessage: 'Please select your type of housing',
    invalidErrorMessage: 'Please select your type of housing'
  },
  marital_status: {
    validationType: 'required',
    requiredErrorMessage: 'Please select your marital status',
    invalidErrorMessage: 'Please select your marital status'
  },
  dependents: {
    validationType: 'required',
    requiredErrorMessage: 'Please select the number of dependents',
    invalidErrorMessage: 'Please select the number of dependents'
  },
  sortcode: {
    validationType: 'pattern',
    pattern: '^\\d{6}$',
    requiredErrorMessage: 'Please enter your sortcode',
    invalidErrorMessage: 'Please enter a valid sortcode'
  },
  account_number: {
    validationType: 'pattern',
    pattern: '^\\d{8}$',
    requiredErrorMessage: 'Please enter your account number',
    invalidErrorMessage: 'Please enter a valid account number'
  },
  employment_type: {
    validationType: 'required',
    requiredErrorMessage: 'Please select your occupation type',
    invalidErrorMessage: 'Please select your occupation type'
  },
  employer_name: {
    validationType: 'isName',
    minLength: 2,
    requiredErrorMessage: 'Please enter your employer name',
    invalidErrorMessage: 'Please enter a valid employer name (minimum 2 characters)'
  },
  employment_length: {
    validationType: 'required',
    requiredErrorMessage: 'Please select your employment duration',
    invalidErrorMessage: 'Please select your employment duration'
  },
  income_amount: {
    validationType: 'isNumber',
    min: 0,
    max: 15000,
    requiredErrorMessage: 'Please enter your income amount',
    invalidErrorMessage: 'Please enter a value between 0 and 15000'
  },
  pay_frequency: {
    validationType: 'required',
    requiredErrorMessage: 'Please select your pay frequency',
    invalidErrorMessage: 'Please select your pay frequency'
  },
  incomeNextDate1: {
    validationType: 'required',
    requiredErrorMessage: 'Please enter your next paydate',
    invalidErrorMessage: 'Please enter your next paydate'
  },
  incomeNextDate2: {
    validationType: 'required',
    requiredErrorMessage: 'Please enter your next to next paydate',
    invalidErrorMessage: 'Please enter your next to next paydate'
  },
  rent: {
    validationType: 'isNumber',
    min: 0,
    max: 15000,
    requiredErrorMessage: 'Please enter your expense amount',
    invalidErrorMessage: 'Please enter a value between 0 and 15000'
  },
  credit_commitments: {
    validationType: 'isNumber',
    min: 0,
    max: 15000,
    requiredErrorMessage: 'Please enter your expense amount',
    invalidErrorMessage: 'Please enter a value between 0 and 15000'
  },
  utility_bills: {
    validationType: 'isNumber',
    min: 0,
    max: 15000,
    requiredErrorMessage: 'Please enter your expense amount',
    invalidErrorMessage: 'Please enter a value between 0 and 15000'
  },
  transport: {
    validationType: 'isNumber',
    min: 0,
    max: 15000,
    requiredErrorMessage: 'Please enter your expense amount',
    invalidErrorMessage: 'Please enter a value between 0 and 15000'
  },
  food_drink: {
    validationType: 'isNumber',
    min: 0,
    max: 15000,
    requiredErrorMessage: 'Please enter your expense amount',
    invalidErrorMessage: 'Please enter a value between 0 and 15000'
  },
  other_expenses: {
    validationType: 'isNumber',
    min: 0,
    max: 15000,
    requiredErrorMessage: 'Please enter your expense amount',
    invalidErrorMessage: 'Please enter a value between 0 and 15000'
  },
  checkbox_expense: {
    validationType: 'isCheckbox',
    requiredErrorMessage: 'Please confirm your income and expenses',
    invalidErrorMessage: 'Please confirm your income and expenses'
  },
  marketing_email_optin_flag: {
    validationType: 'optional'
  },
  consent_privacy: {
    validationType: 'isCheckbox',
    requiredErrorMessage: 'Please confirm your understanding',
    invalidErrorMessage: 'Please confirm your understanding'
  }
};

export const FormProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [formData, setFormData] = useState<{ [key: string]: string }>(() => {
    try {
      const storedData = localStorage.getItem(FORM_STORAGE_KEY);
      return storedData ? JSON.parse(storedData) : {};
    } catch (error) {
      UIUtils.trackException('Reading from localStorage', error);
      return {};
    }
  });

  // Initialize touchedFields as empty - don't persist touched state
  const [touchedFields, setTouchedFields] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    try {
      localStorage.setItem(FORM_STORAGE_KEY, JSON.stringify(formData));
    } catch (error) {
      UIUtils.trackException('Saving to localStorage', error);
    }
  }, [formData]);

  const validateField = (key: string, value: string): string | null => {
    const validator = validators[key];
    if (!validator) return null;

    // Handle optional fields
    if (validator.validationType === 'optional') {
      return null;
    }

    // Required check for all non-optional fields
    if (!value && validator.validationType !== 'optional') {
      return validator.requiredErrorMessage || 'This field is required';
    }

    switch (validator.validationType) {
      case 'isNumber':
        const number = parseFloat(value);
        if (isNaN(number)) {
          return validator.invalidErrorMessage || 'This field must be a number';
        }
        if (validator.min !== undefined && number < validator.min) {
          return validator.invalidErrorMessage || `Value must be at least ${validator.min}`;
        }
        if (validator.max !== undefined && number > validator.max) {
          return validator.invalidErrorMessage || `Value must be at most ${validator.max}`;
        }
        break;

      case 'isName':
        if (!/^[a-zA-Z\s'-]+$/.test(value)) {
          return validator.invalidErrorMessage || 'Please enter a valid name';
        }
        if (validator.minLength && value.length < validator.minLength) {
          return validator.invalidErrorMessage || `Must be at least ${validator.minLength} characters`;
        }
        break;

      case 'pattern':
        if (!new RegExp(validator.pattern!).test(value)) {
          return validator.invalidErrorMessage || 'Invalid format';
        }
        break;

      case 'isPostcode':
        if (!/^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i.test(value)) {
          return validator.invalidErrorMessage || 'Please enter a valid postcode';
        }
        break;

      case 'isCheckbox':
        if (value !== 'true') {
          return validator.requiredErrorMessage || 'This checkbox must be checked';
        }
        break;

      case 'required':
        if (!value) {
          return validator.requiredErrorMessage || 'This field is required';
        }
        break;

      case 'nonEmpty':
        if (!value.trim()) {
          return validator.requiredErrorMessage || 'This field is required';
        }
        break;
    }

    return null;
  };

  const updateField = (key: string, value: string) => {
    setFormData(prev => ({ ...prev, [key]: value }));
    setTouchedFields(prev => ({ ...prev, [key]: true }));
  };

  const isFieldTouched = (key: string): boolean => {
    return touchedFields[key] || false;
  };

  const markFieldsAsTouched = (keys: string[]) => {
    const newTouchedFields = { ...touchedFields };
    keys.forEach(key => {
      newTouchedFields[key] = true;
    });
    setTouchedFields(newTouchedFields);
  };

  const clearTouchedFields = () => {
    setTouchedFields({});
  };

  const validateForm = (): boolean => {
    let isValid = true;
    let firstErrorElement: HTMLElement | null = null;
    Object.entries(validators).forEach(([key, validator]) => {
      if (validator.validationType !== 'optional') {
        const error = validateField(key, formData[key] || '');
        if (error !== null) {
          isValid = false;
          const errorElement = document.querySelector(`[name="${key}"]`);
          if (!firstErrorElement && errorElement) {
            firstErrorElement = errorElement as HTMLElement;
          }
        }
      }
    });
    if (firstErrorElement) {
      (firstErrorElement as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'center' });
      (firstErrorElement as HTMLElement).focus();
    }
    return isValid;
  };

  const validatePageFields = (fields: string[]): boolean => {
    let isValid = true;
    let firstErrorElement: HTMLElement | null = null;
    fields.forEach(key => {
      // Skip validation for optional fields
      if (validators[key]?.validationType === 'optional') {
        return;
      }
      // For required fields, validate even if they don't exist in formData
      const error = validateField(key, formData[key] || '');
      if (error !== null) {
        const errorElement = document.querySelector(`[name="${key}"]`);
        if (!firstErrorElement && errorElement) {
          firstErrorElement = errorElement as HTMLElement;
        }
        UIUtils.trackFormSubmitErrors(key,error);
        isValid = false;
      }
    });
    if (firstErrorElement) {
      (firstErrorElement as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'center' });
      (firstErrorElement as HTMLElement).focus();
    }
    if (!isValid) {
      markFieldsAsTouched(fields);
    }
    return isValid;
  };

  const submitForm = async () => {
    // Convert checkbox values to booleans before submission
    const processedFormData: FormData = { ...formData };
    const checkboxFields = ['checkbox_expense', 'consent_privacy', 'marketing_email_optin_flag'];
    const lsrc = UIUtils.getCookieValue('lsrc') || 'default';
    checkboxFields.forEach(field => {
      if (field in processedFormData) {
        processedFormData[field] = processedFormData[field] === 'true';
      }
    });

    return await api.postApplicationForm(processedFormData, lsrc);
  };

  const submitShortForm = async () => {
    // Convert checkbox values to booleans before submission
    const processedFormData: FormData = { ...formData };
    const checkboxFields = ['checkbox_expense', 'consent_privacy', 'marketing_email_optin_flag'];
    const lsrc = UIUtils.getCookieValue('lsrc') || 'default';
    checkboxFields.forEach(field => {
      if (field in processedFormData) {
        processedFormData[field] = processedFormData[field] === 'true';
      }
    });

    return await api.postShortForm(processedFormData, lsrc);
  };

  return (
    <FormContext.Provider value={{
      formData,
      touchedFields,
      updateField,
      validateField,
      isFieldTouched,
      markFieldsAsTouched,
      validateForm,
      validatePageFields,
      submitForm,
      submitShortForm,
      clearTouchedFields
    }}>
      {children}
    </FormContext.Provider>
  );
};

export const useForm = (): FormContextType => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error('useForm must be used within a FormProvider');
  }
  return context;
};
