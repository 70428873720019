import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import config from '../configs/config';
import { UIUtils } from './uiUtils';

interface SignUpData {
  first_name: string;
  last_name: string;
  email: string;
  dob: string;
  privacy_consent: boolean;
  receive_updates: boolean;
  created_by: string;
  social_token?: {
    token: string;
    provider: 'google' | 'apple';
    device_info: DeviceInfo;
  };
  apple_sub?: string;  // Added for Apple sign-in
}

interface DeviceInfo {
  device: string;
  browser: string;
  operating_system: string;
}

interface LeadLandingResponse {
  eligibility: boolean;
  first_name: string;
  is_verified: boolean;
  email: string;
  access_token: string;
}

interface TokenSignInResponse {
  status: 'SUCCESS' | 'PENDING_SIGNUP';
  access_token?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  apple_sub?: string;
  needs_email?: boolean;
}

const TOKEN_STORAGE_KEY = 'afforda_auth_token';

// Create a separate axios instance for lead landing API calls
const leadLandingApi = axios.create({
  baseURL: config.API_URL_BASE,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add response interceptor for tracking only (no redirect)
leadLandingApi.interceptors.response.use(
  (response) => {
    UIUtils.trackAPIResponse(response.config.url, response.config.method, response.status.toString(), response.statusText);
    return response;
  },
  (error) => {
    if (error.response) {
      UIUtils.trackAPIResponse(error.response.config.url, error.response.config.method, error.response.status.toString(), error.response.statusText);
    }
    return Promise.reject(error);
  }
);

const getDeviceInfo = (): DeviceInfo => ({
  device: 'web',
  browser: navigator.userAgent,
  operating_system: navigator.platform
});

class Api {
  private static instance: Api;
  private api: AxiosInstance;
  private token: string | null = null;

  private constructor() {
    // Initialize token from localStorage if it exists
    this.token = localStorage.getItem(TOKEN_STORAGE_KEY);

    this.api = axios.create({
      baseURL: config.API_URL_BASE,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Request interceptor to add auth token
    this.api.interceptors.request.use((config) => {
      if (this.token) {
        config.headers['Authorization'] = `Bearer ${this.token}`;
      }
    
      // Get the values of "lsrc" and "persistent_sessionid" from the browser cookies
      const persistent_sessionid = document.cookie.replace(/(?:(?:^|.*;\s*)persistent_sessionid\s*=\s*([^;]*).*$)|^.*$/, "$1");
    
      // Add the values to the request headers
      config.headers['persistent-sessionid'] = persistent_sessionid;
    
      UIUtils.trackAPIRequest(config.url, config.method);
      return config;
    });
    // Response interceptor to handle 401 errors
    this.api.interceptors.response.use(
      (response) => {
        // Log the response status and URL to New Relic
        UIUtils.trackAPIResponse(response.config.url, response.config.method, response.status.toString(), response.statusText);
    
        return response;
      },
      (error) => {
        if (error.response) {
          UIUtils.trackAPIResponse(error.response.config.url, error.response.config.method, error.response.status.toString(), error.response.statusText);
          
          if (error.response.status === 401) {
            // Clear auth data
            this.clearToken();
            localStorage.removeItem('afforda_user_data');
            sessionStorage.clear();
            
            // Redirect to login page
            window.location.href = '/login';
          }
        }
        return Promise.reject(error);
      }
    );
  }

  public static getInstance(): Api {
    if (!Api.instance) {
      Api.instance = new Api();
    }
    return Api.instance;
  }

  public setToken(token: string) {
    this.token = token;
    localStorage.setItem(TOKEN_STORAGE_KEY, token);
  }

  public clearToken() {
    this.token = null;
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  }

  private async request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.api.request(config);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      }
      throw error;
    }
  }

  public async loginWithGoogle(idToken: string): Promise<AxiosResponse<TokenSignInResponse>> {
    return this.request({
      method: 'POST',
      url: 'v1/sign-in/google/',
      data: {
        token: idToken,
        device_info: getDeviceInfo()
      },
    });
  }

  public async loginWithApple(idToken: string): Promise<AxiosResponse<TokenSignInResponse>> {
    return this.request({
      method: 'POST',
      url: '/v1/sign-in/apple/',
      data: {
        token: idToken,
        device_info: getDeviceInfo()
      },
    });
  }

  public async postEmailForVerification(email: string): Promise<AxiosResponse<any>> {
    return this.request({
      method: 'POST',
      url: '/v1/login/',
      data: { email },
    });
  }

  public async fetchVerificationStatus(totpUID: string): Promise<AxiosResponse<any>> {
    return this.request({
      method: 'GET',
      url: `/v1/otp-verification/${totpUID}/`,
    });
  }

  public async verifyCode(totpUID: string, verificationCode: string, lsrc: string): Promise<AxiosResponse<any>> {
    return this.request({
      method: 'POST',
      url: `/v1/otp-verification/${totpUID}/`,
      data: {
        totp_code: verificationCode,
        source: lsrc || 'default',
      },
    });
  }

  public async resendVerificationCode(totpUID: string): Promise<AxiosResponse<{ status: number }>> {
    return this.request({
      method: 'POST',
      url: `/v1/otp/${totpUID}/resend/`,
    });
  }

  public async signUpUser(signUpData: SignUpData): Promise<AxiosResponse<any>> {
    return this.request({
      method: 'POST',
      url: '/v1/signup/',
      data: signUpData,
    });
  }

  public async fetchUserData(): Promise<AxiosResponse<any>> {
    return this.request({
      method: 'GET',
      url: '/v1/customer/?ld&dd&bb&bs&pd&ga',
    });
  }

  public async postApplicationForm(formData: any, lsrc: string): Promise<any> {
    return this.request({
      method: 'POST',
      url: '/v1/application/',
      data: { ...formData, source: lsrc, created_by: 'mobile_app' },
    });
  }

  public async postShortForm(formData: any, lsrc: string): Promise<any> {
    return this.request({
      method: 'POST',
      url: '/v1/short-benefits/',
      data: { ...formData, source: lsrc, created_by: 'mobile_app' },
    });
  }

  public async getRewardsLink(perkID?: string, category?: string, lsrc?: string): Promise<any> {
    const data: any = { source: lsrc };
    if (perkID) data.perk_id = perkID;
    if (category) data.category = category;

    return this.request({
      method: 'POST',
      url: '/v1/rewards/login/',
      data,
    });
  }

  public async fetchAddressInfo(postCode: string): Promise<any> {
    return this.request({
      method: 'POST',
      url: `${config.LS_URL}/api/common/lending-stream/commonService/findaddress/`,
      data: {
        method: 'GET',
        params: {
          type: 'addr',
          postcode: postCode,
        },
      },
    });
  }

  public async fetchOffers(categories?: number[], userGoalId?: number): Promise<any> {
    const data: any = { categories };
    if (userGoalId && userGoalId !== -1) data.user_goal_id = userGoalId;

    return this.request({
      method: 'POST',
      url: '/v1/offers/',
      data,
    });
  }

  public async postFullBenefitsCompletion(): Promise<any> {
    return this.request({
      method: 'POST',
      url: '/v1/full-benefits/',
      data: {},
    });
  }

  public async fetchArticles(userGoalStatement: string): Promise<any> {
    return this.request({
      method: 'POST',
      url: '/v1/suggest-articles/',
      data: { user_goal: userGoalStatement },
    });
  }

  public async processUserGoal(goalStatement: string, parentGoalId?: number): Promise<AxiosResponse<any>> {
    const data: any = { user_goal: goalStatement };
    if (parentGoalId) data.parent_goal_id = parentGoalId;

    return this.request({
      method: 'POST',
      url: '/v1/user-goals/',
      data,
    });
  }

  public async capturePrivacyPolicyConsent(): Promise<any> {
    return this.request({
      method: 'POST',
      url: '/v1/privacy-policy/',
    });
  }

  public async updateMarketingConsent(emailMktDncFlag: boolean): Promise<any> {
    return this.request({
      method: 'POST',
      url: '/v1/customer/',
      data: { marketing_email_dnc_flag: emailMktDncFlag },
    });
  }

  public async processOneClickUnsubscribe(customerUID: string, source: string): Promise<any> {
    return this.request({
      method: 'POST',
      url: `/v1/unsubscribe/${customerUID}`,
      data: { source },
    });
  }

  public async fetchLeadLandingData(uuid: string): Promise<AxiosResponse<LeadLandingResponse>> {
    return leadLandingApi.request({
      method: 'GET',
      url: `/v1/lead_landing/${uuid}/`,
      headers: {
        'Authorization': `Bearer ${config.LEAD_LANDING_TOKEN}`
      }
    });
  }

  public async submitLeadLanding(access_token: string, data: any): Promise<AxiosResponse<any>> {
    return leadLandingApi.request({
      method: 'POST',
      url: `/v1/lead_landing/`,
      headers: {
        'Authorization': `Bearer ${access_token}`
      },
      data,
    });
  }
}

const api = Api.getInstance();

export default api;

// Export specific functions
export const loginWithGoogle = (idToken: string) => api.loginWithGoogle(idToken);
export const loginWithApple = (idToken: string) => api.loginWithApple(idToken);
export const fetchUserData = () => api.fetchUserData();
export const postEmailForVerification = (email: string) => api.postEmailForVerification(email);
export const verifyCode = (totpUID: string, verificationCode: string, lsrc: string) => api.verifyCode(totpUID, verificationCode, lsrc);
export const processUserGoal = (goalStatement: string, parentGoalId?: number) => api.processUserGoal(goalStatement, parentGoalId);
export const setToken = (token: string) => api.setToken(token);
export const processOneClickUnsubscribe = (customerUID: string, source: string) => api.processOneClickUnsubscribe(customerUID, source);
export const fetchLeadLandingData = (uuid: string) => api.fetchLeadLandingData(uuid);
export const submitLeadLanding = (access_token: any, data: any) => api.submitLeadLanding(access_token, data);
