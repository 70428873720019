import React, { useEffect } from 'react';
import { BrowserRouter as Router, useRoutes, RouteObject, Navigate, useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import routes from './routes';
import { AuthProvider, useAuth } from './utils/auth';
import { UserProvider } from './contexts/UserContext';
import { FormProvider } from './contexts/FormContext';
import { BottomNavBarProvider } from './contexts/BottomNavBarContext';
import { SnackbarProvider } from './components/SnackbarProvider';
import { useInitSnackbar } from './hooks/useInitSnackbar';
import Header from './components/Header';
import BottomNavBar from './components/BottomNavBar';
import config from './configs/config';
import { UIUtils } from './utils/uiUtils';
import useSourceTracking from './hooks/useSourceTracking';


const AppRoutes: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;


  // Process routes to add authentication check
  const processedRoutes: RouteObject[] = routes.map(route => {
    // If route is protected and user is not logged in
    if (route.protected && !user) {
      return {
        ...route,
        element: (
          <Navigate
            to="/login"
            state={{ from: location }}
            replace
          />
        )
      };
    }

    // If we're logged in and trying to access login/signup pages, redirect to customer home
    if (user && (currentPath === '/login' || currentPath === '/signup')) {
      return {
        ...route,
        element: <Navigate to="/customer-home" replace />
      };
    }

    return route;
  });

  const element = useRoutes(processedRoutes);
  useInitSnackbar();

  return (
    <div className="flex flex-col">
      <Header />
      <main>
        <div className="container-content pt-0 pb-[72px]">
          {element}
        </div>
      </main>
      <BottomNavBar />
    </div>
  );
};

// Function to track clicks
const trackLinkClick = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  if (target.tagName === 'A') {
    const anchor = target as HTMLAnchorElement;
    const trackValue = anchor.getAttribute('data-track') || anchor.textContent || 'No text';
    UIUtils.trackExternalLinkRedirects(trackValue);
  }
};

// Function to track blur events
const trackFormElementBlur = (event: FocusEvent) => {
  const target = event.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
  const ignoreTracking = target.getAttribute('data-ignore') === "true";
  const elementName = target.name || target.id || 'Unnamed Element';
  let elementValue: string | boolean = target.value;
  const isSensitive = target.getAttribute('data-sensitive') === 'true';

  // Handle checkboxes separately
  if (target.type === 'checkbox') {
    elementValue = (target as HTMLInputElement).checked.toString();
  }
  if (!ignoreTracking) {
    UIUtils.trackInput(elementName, elementValue, isSensitive);
  }
};

const App: React.FC = () => {
  useSourceTracking();
  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('click', trackLinkClick);
    // Add event listener for blur events
    const formElements = document.querySelectorAll<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>('input, select, textarea');
    formElements.forEach(element => {
      element.addEventListener('blur', trackFormElementBlur as EventListener);
    });

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('click', trackLinkClick);
      formElements.forEach(element => {
        element.removeEventListener('blur', trackFormElementBlur as EventListener);
      });
    };
  }, []);
  return (
    <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <UserProvider>
          <FormProvider>
            <BottomNavBarProvider>
              <SnackbarProvider>
                <Router>
                  <AppRoutes />
                </Router>
              </SnackbarProvider>
            </BottomNavBarProvider>
          </FormProvider>
        </UserProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
