// Import all configs
import developmentConfig from './config.development';
import stagingConfig from './config.staging';
import productionConfig from './config.production';
import { AppConfig } from './types';

// Config mapping
const configs: { [key: string]: AppConfig } = {
  development: developmentConfig,
  staging: stagingConfig,
  production: productionConfig,
};

// Get current environment
const env = process.env.REACT_APP_ENV || 'development';

// Export the config for the current environment
const config: AppConfig = configs[env];

export default config;
