import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../contexts/FormContext';
import { useUser } from '../../contexts/UserContext';
import Loader from '../../components/Loader';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import { UIUtils } from '../../utils/uiUtils';

interface StepConfig {
  component: React.ComponentType<any>;
  fields: string[];
}

const steps: StepConfig[] = [
  { component: Step1, fields: ['borrow_amount', 'use_of_funds'] },
  { component: Step2, fields: ['title', 'first_name', 'last_name', 'dob', 'mobile_number'] },
  { component: Step3, fields: ['postcode', 'house_name', 'street', 'post_town', 'time_at_address', 'type_of_housing'] },
  { component: Step4, fields: ['marital_status', 'dependents'] },
  { component: Step5, fields: ['sortcode', 'account_number'] },
  { component: Step6, fields: ['employment_type', 'employer_name', 'employment_length', 'income_amount', 'pay_frequency', 'incomeNextDate1', 'incomeNextDate2'] },
  { component: Step7, fields: ['rent', 'credit_commitments', 'utility_bills', 'transport', 'food_drink', 'other_expenses'] },
  { component: Step8, fields: ['checkbox_expense', 'consent_privacy', 'marketing_email_optin_flag'] }
];

const LoanApplication: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSold, setIsSold] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const { formData, submitForm, validatePageFields, markFieldsAsTouched } = useForm();
  const { user, userGoal, benefitsSummary, benefitsBreakdown, marketing_email_dnc_flag, updateUserData } = useUser();
  const navigate = useNavigate();
  // Function to track blur events
const trackFormElementBlur = (event: FocusEvent) => {
  const target = event.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
  const ignoreTracking = target.getAttribute('data-ignore') === "true";
  const elementName = target.name || target.id || 'Unnamed Element';
  let elementValue: string | boolean = target.value;
  const isSensitive = target.getAttribute('data-sensitive') === 'true';

  // Handle checkboxes separately
  if (target.type === 'checkbox') {
    elementValue = (target as HTMLInputElement).checked.toString();
  }
  if (!ignoreTracking) {
    UIUtils.trackInput(elementName, elementValue, isSensitive);
  }
};
  useEffect(() => {
    if(currentStep!==0) {
      // Add event listener for blur events
      const formElements = document.querySelectorAll<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>('input, select, textarea');
      formElements.forEach(element => {
        element.addEventListener('blur', trackFormElementBlur as EventListener);
      });

      // Cleanup function to remove the event listener
      return () => {
        formElements.forEach(element => {
          element.removeEventListener('blur', trackFormElementBlur as EventListener);
        });
      };
    };
  }, [currentStep]);

  const handleNext = () => {
    const currentFields = steps[currentStep].fields;
    
    // Mark fields as touched and validate
    markFieldsAsTouched(currentFields);
    if (!validatePageFields(currentFields)) {
      UIUtils.trackFormSubmit(currentStep.toString(),"error");
      return;
    }

    if (currentStep < steps.length - 1) {
      UIUtils.trackFormSubmit(currentStep.toString(),"success");
      setCurrentStep(currentStep + 1);
    } else {
      UIUtils.trackFormSubmit(currentStep.toString(),"success");
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      navigate(-1);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const response = await submitForm();
      const { status, response: respBody } = response.data;
      UIUtils.trackGAEvents('loan_application_submitted', status);
      // Update marketing_email_dnc_flag in UserContext based on the opt-in value
      updateUserData({
        user,
        userGoal,
        benefitsSummary,
        benefitsBreakdown,
        marketing_email_dnc_flag: formData.marketing_email_optin_flag === undefined 
          ? marketing_email_dnc_flag  // Keep existing preference if checkbox wasn't shown
          : formData.marketing_email_optin_flag !== 'true'  // Update based on checkbox if it was shown
      });

      if (status === 'sold') {
        UIUtils.trackNREvents("phonexa_sold", respBody.redirect_url);
        UIUtils.trackGAEvents('phonexa_sold', respBody.redirect_url);
        setIsSold(true);
        setRedirectUrl(respBody.redirect_url);
        window.location.href = respBody.redirect_url;
      } else if (status === 'rejected' || status === 'error') {
        UIUtils.trackNREvents("phonexa_declined", status);
        UIUtils.trackGAEvents('phonexa_declined', status);
        navigate('/regret');
      } else if (status === 'new' || status === 'timeout') {
        UIUtils.trackNREvents("phonexa_declined", status);
        UIUtils.trackGAEvents('phonexa_declined', status)
        setIsSubmitting(false);
        // Show error message
        const errorMessage = document.createElement('div');
        errorMessage.className = 'fixed top-4 right-4 bg-red-500 text-white px-4 py-2 rounded';
        errorMessage.textContent = 'Something went wrong. Please try again later.';
        document.body.appendChild(errorMessage);
        setTimeout(() => errorMessage.remove(), 5000);
      } else {
        navigate('/customer-home');
      }
    } catch (error) {
      UIUtils.trackException('Application submission', error);
      setIsSubmitting(false);
      // Show error message
      const errorMessage = document.createElement('div');
      errorMessage.className = 'fixed top-4 right-4 bg-red-500 text-white px-4 py-2 rounded';
      errorMessage.textContent = 'Something went wrong. Please try again later.';
      document.body.appendChild(errorMessage);
      setTimeout(() => errorMessage.remove(), 5000);
    }
  };

  const renderCurrentStep = () => {
    const StepComponent = steps[currentStep].component;
    return <StepComponent onStepChange={setCurrentStep} />;
  };

  if (isSubmitting) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white">
        <Loader message="We are finding you a lender. This may take a few minutes. Please don't refresh or use the back button as this could interrupt your application" />
      </div>
    );
  }

  if (isSold) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white">
        <div className="max-w-2xl mx-auto p-8 text-center">
          <h2 className="text-3xl font-bold mb-4">Thank you for your loan application.</h2>
          <p className="text-lg mb-4">We have a lender that may be able to help you; they need a bit more information from you first.</p>
          <h3 className="text-xl font-semibold mb-2">What next?</h3>
          <p className="text-lg mb-6">Click on Continue application, to view your options.</p>
          <button
            onClick={() => window.location.href = redirectUrl}
            className="btn-primary"
          >
            Continue application
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header
      <header className="bg-white shadow">
        <div className="max-w-3xl mx-auto px-4 py-4 flex items-center">
          <h1 className="text-2xl font-bold">Apply for a Loan</h1>
        </div>
      </header> */}
      <div className="flex items-center">
          <h1 className="text-xl">Apply for a loan</h1>
        </div>

      <main className="flex-grow container mx-auto max-w-3xl">
        {/* Progress indicator */}
        <div className="mb-4 pt-4">
          <div className="h-2 bg-gray-200 rounded-full">
            <div
              className="h-2 bg-primary rounded-full transition-all duration-300"
              style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
            />
          </div>
          <div className="mt-2 text-sm text-gray-600">
            Step {currentStep + 1} of {steps.length}
          </div>
        </div>

        {/* Current step form */}
        {renderCurrentStep()}

        {/* Navigation buttons */}
        <div className="sticky bottom-0 bg-white border-t p-4 mt-6">
          <div className="max-w-3xl mx-auto flex justify-between items-center">
            <div className="flex-1">
              {currentStep > 0 && (
                <button
                  onClick={handleBack}
                  disabled={isSubmitting}
                  className="btn-secondary"
                >
                  Previous
                </button>
              )}
            </div>
            <button
              onClick={handleNext}
              disabled={isSubmitting}
              className="btn-primary"
            >
              {currentStep === steps.length - 1 ? 'Submit' : 'Next'}
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LoanApplication;
