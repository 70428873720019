import { useForm } from '../contexts/FormContext';
import { useUser } from '../contexts/UserContext';
import { useAuth } from './auth';
import { CustomerData, UserGoal, BenefitsSummary, BenefitsBreakdown } from './types';
import { UIUtils } from './uiUtils';
import api from './api';
import { useLocation } from 'react-router-dom';

export const usePostLoginProcessor = () => {
  const { updateField } = useForm();
  const { updateUserData } = useUser();
  const { setUser } = useAuth();
  const location = useLocation();

  const processPostLogin = (
    jsonData: any, 
    accessToken: string, 
    loginType: string
  ): { redirectTo: string } => {
    const customerData = jsonData as CustomerData;
    let userGoal = null;
    let benefitsSummary = null;
    let benefitsBreakdown = null;
    
    // Get redirect URL from location state if available
    const redirectState = location.state as { from?: string };
    let redirectTo = redirectState?.from || '/customer-home';

    if (jsonData.goal_data && jsonData.goal_data.response && !jsonData.goal_data.response.error) {
      userGoal = jsonData.goal_data as UserGoal;
    }

    if (jsonData.benefits_summary && Object.keys(jsonData.benefits_summary).length > 0) {
      benefitsSummary = jsonData.benefits_summary as BenefitsSummary;
    }

    if (jsonData.benefits_breakdown && Object.keys(jsonData.benefits_breakdown).length > 0) {
      benefitsBreakdown = jsonData.benefits_breakdown as BenefitsBreakdown;
    }

    // Set token in API first
    api.setToken(accessToken);
    
    // Then update auth context with the access token
    setUser({ accessToken });

    // Store token in localStorage
    localStorage.setItem('afforda_auth_token', accessToken);

    // Pre-populate form data
    UIUtils.prefillFormData(customerData, updateField);
    
    // Update UserContext with the data
    updateUserData({
      user: customerData.basic_profile,
      userGoal,
      benefitsSummary,
      benefitsBreakdown,
      marketing_email_dnc_flag: jsonData.dnc_preferences?.marketing_email_dnc_flag ?? false
    });

    // Override redirect based on required flows
    if (jsonData.need_privacy_policy_consent) {
      redirectTo = '/privacy-consent';
    } else if (loginType === 'lead_id') {
      redirectTo = '/suggestions';
    } else if (!userGoal && !redirectState?.from) {
      // Only redirect to ask-afforda if there's no specific redirect requested
      redirectTo = '/ask-afforda';
    }

    return { redirectTo };
  };

  return { processPostLogin };
};
