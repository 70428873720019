import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import { UIUtils } from '../utils/uiUtils';
import config from '../configs/config';
import api from '../utils/api';
import { usePostLoginProcessor } from '../utils/PostLoginProcessor';
import { useAuth } from '../utils/auth';

interface AppleSignInRenderProps {
  onClick: () => void;
  disabled?: boolean;
}

interface SocialSignInProps {
  onLoadingChange?: (isLoading: boolean) => void;
  redirectPath?: string;
}

// Condition variables to control sign-in visibility
const ENABLE_GOOGLE_SIGNIN = false;
const ENABLE_APPLE_SIGNIN = false;

export const SocialSignIn: React.FC<SocialSignInProps> = ({ onLoadingChange, redirectPath }) => {
  const navigate = useNavigate();
  const { processPostLogin } = usePostLoginProcessor();
  const { loginWithGoogle, loginWithApple } = useAuth();

  const processGoogleSignIn = async (credentialResponse: any) => {
    onLoadingChange?.(true);
    try {
      const { credential } = credentialResponse;

      if (!credential) {
        throw new Error('No credential received from Google');
      }

      const response = await loginWithGoogle(credential);
      
      if (response.data.status === 'PENDING_SIGNUP') {
        // Store social token for signup
        sessionStorage.setItem('social_signup_data', JSON.stringify({
          token: credential,
          provider: 'google',
          email: response.data.email,
          first_name: response.data.first_name,
          last_name: response.data.last_name
        }));
        
        // Navigate to signup with pre-filled data
        navigate('/signup', { 
          state: {
            email: response.data.email,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            social_signup: true,
            from: redirectPath // Pass through the redirect path
          }
        });
      } else if (response.data.status === 'SUCCESS' && response.data.access_token) {
        // Now we can safely fetch user data since token is set
        const userData = await api.fetchUserData();

        if (!userData.data) {
          throw new Error('No user data received from server');
        }

        const { redirectTo } = processPostLogin(
          userData.data,
          response.data.access_token,
          'google'
        );

        UIUtils.showMessageOnSnackBar('Successfully signed in');
        navigate(redirectTo, { replace: true });
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error: any) {
      UIUtils.trackException('Google Sign In', error);
      UIUtils.showMessageOnSnackBar(
        error.response?.data?.message || 
        error.message || 
        'Error signing in. Please try again.'
      );
    } finally {
      onLoadingChange?.(false);
    }
  };

  const processAppleSignIn = async (data: any) => {
    try {
      onLoadingChange?.(true);
      
      if (!data.authorization || !data.authorization.id_token) {
        throw new Error('No credential received from Apple');
      }

      const response = await loginWithApple(data.authorization.id_token);
      
      if (response.data.status === 'PENDING_SIGNUP') {
        // Store social token for signup
        sessionStorage.setItem('social_signup_data', JSON.stringify({
          token: data.authorization.id_token,
          provider: 'apple',
          email: response.data.email,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          apple_sub: response.data.apple_sub,
          needs_email: response.data.needs_email
        }));
        
        // Navigate to signup with pre-filled data
        navigate('/signup', { 
          state: {
            email: response.data.email,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            social_signup: true,
            needs_email: response.data.needs_email,
            apple_sub: response.data.apple_sub,
            from: redirectPath // Pass through the redirect path
          }
        });
      } else if (response.data.status === 'SUCCESS' && response.data.access_token) {
        const userData = await api.fetchUserData();

        if (!userData.data) {
          throw new Error('No user data received from server');
        }

        const { redirectTo } = processPostLogin(
          userData.data,
          response.data.access_token,
          'apple'
        );

        UIUtils.showMessageOnSnackBar('Successfully signed in');
        navigate(redirectTo, { replace: true });
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error: any) {
      UIUtils.trackException('Apple Sign In', error);
      UIUtils.showMessageOnSnackBar(
        error.response?.data?.message || 
        error.message || 
        'Error signing in. Please try again.'
      );
    } finally {
      onLoadingChange?.(false);
    }
  };

  const handleGoogleError = () => {
    UIUtils.trackException('Google sign in', 'Error callback triggered');
    UIUtils.showMessageOnSnackBar('Google sign in failed. Please try again.');
  };

  const handleAppleError = (error: any) => {
    UIUtils.trackException('Apple Sign In', error);
    UIUtils.showMessageOnSnackBar('Apple sign in failed. Please try again.');
  };

  return (
    <>
      {((ENABLE_GOOGLE_SIGNIN || ENABLE_APPLE_SIGNIN) && (<div className="flex items-center my-4">
        <div className="flex-1 border-t border-gray-300"></div>
        <span className="px-4">Alternatively, sign in or create an account using these options:</span>
        <div className="flex-1 border-t border-gray-300"></div>
      </div>))}

      <div className="flex flex-col items-center gap-4">
        {ENABLE_GOOGLE_SIGNIN && (
          <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
            <GoogleLogin
              onSuccess={processGoogleSignIn}
              onError={handleGoogleError}
              useOneTap
              type="standard"
              theme="filled_blue"
              size="large"
              width="300"
              text="signin_with"
              shape="rectangular"
            />
          </GoogleOAuthProvider>
        )}

        {ENABLE_APPLE_SIGNIN && (
          <AppleSignin
            authOptions={{
              clientId: config.APPLE_SERVICE_ID,
              scope: 'name email',
              redirectURI: window.location.origin,
              usePopup: true
            }}
            uiType="dark"
            onSuccess={processAppleSignIn}
            onError={handleAppleError}
            render={(renderProps: AppleSignInRenderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="flex items-center justify-center gap-2 w-[300px] h-[40px] bg-black text-white rounded-md hover:bg-gray-800 transition-colors disabled:opacity-50 disabled:cursor-not-allowed text-[14px]"
              >
                <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                  <path d="M17.05 20.28c-.98.95-2.05.88-3.08.4-1.09-.5-2.08-.52-3.2 0-.83.38-1.71.89-3.1.41C3.93 19.75 3.13 14.89 3.13 14.89c0-3.28 2.1-4.84 3.93-4.92 1.45-.06 2.82.96 3.71.96.89 0 2.56-1.18 4.32-1.01.73.03 2.82.3 4.16 2.22-3.67 2.14-3.08 6.99.8 8.14zm-4.44-13.42c-.7.86-1.86 1.52-2.98 1.45-.21-1.74.63-3.54 1.43-4.31.89-.86 2.01-1.52 3.07-1.45.17 1.77-.67 3.54-1.52 4.31z"/>
                </svg>
                Sign in with Apple
              </button>
            )}
          />
        )}
      </div>
    </>
  );
};

export default SocialSignIn;
