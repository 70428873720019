import { AppConfig } from "./types";

const config: AppConfig = {
  API_URL_BASE: 'http://localhost:8002',
  WORDPRESS_URL: 'https://afforda.co.uk',
  LS_URL: 'https://www.lendingstream.co.uk',
  GOOGLE_CLIENT_ID: '386269864544-laugptg9t8tvqgegmjmk8co860gf4qpb.apps.googleusercontent.com',
  APPLE_SERVICE_ID: 'com.gaincredit.afforda.web.staging',
  ENV: 'development',
  LEAD_LANDING_TOKEN: '15902a06b09d867d8487ac83d1a985f797c17b26b003ea329856a47939f88010'
};

export default config;
